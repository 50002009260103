import React from 'react';
import elements from "../components/modules/elements";
import PropTypes from 'prop-types';

const ModuleElementComponent = (props) => {
    const ComponentFunction = elements.find((element) => element.type === props.elementType).component;

    return (
        <ComponentFunction
            {...props.element}
            updateContent={props.updateContent}
            findContent={props.findContent}
            removeContentFromGrid={props.removeContentFromGrid}
            addContent={props.addContent}
            removeContent={props.removeContent}
            checkboxDimension={props.checkboxDimension}
            dimensions={props.dimensions}
            updateAnswer={props.updateAnswer}
            removeAnswer={props.removeAnswer}
            answer={(props.getAnswer) ? props.getAnswer(props.element.id) : null}
            getAnswer={props.getAnswer}
        />
    );
};

ModuleElementComponent.propTypes = {
    element: PropTypes.object.isRequired,
    elementType: PropTypes.string.isRequired,
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    findContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    addContent: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
}

export default ModuleElementComponent;