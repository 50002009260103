import jwtDecode from "jwt-decode";
import axios from "axios";
import config from "../config/config";

const Token = {
    validate: (token) => {
        let decodedToken = Token.getTokenData(token),
            timestampExpire = decodedToken.exp,
            timestampNow = Math.floor(Date.now() / 1000);

        return timestampExpire > timestampNow;
    },
    refresh: (callbackSuccess, callbackError, refreshToken) => {
        let data = {
            refresh_token: refreshToken
        };

        axios.post(config.apiUrl + `/token/refresh`, data, {withCredentials: true})
            .then(res => {
                if (res.data.token && res.data.refresh_token) {
                    callbackSuccess(res);
                } else {
                    callbackError();
                }
            })
            .catch(() => {
                callbackError();
            });
    },
    getTokenData: (token) => {
        return jwtDecode(token);
    },
    hasRole: (tokenData, role) => {
        if (tokenData.roles && tokenData.roles.length) {
            const findRole = tokenData.roles.find(tokenRole => tokenRole === role);

            if (findRole) {
                return true;
            }
        }

        return false;
    },
    hasRoles: (tokenData, roles) => {
        if (tokenData.roles && tokenData.roles.length) {
            const findRole = tokenData.roles.some(r=> roles.indexOf(r) >= 0);

            if (findRole) {
                return true;
            }
        }

        return false;
    }
};

export default Token;