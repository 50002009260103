import React from "react";
import List from "@material-ui/core/List";
import SidebarItem from "./SidebarItem";

import {
    faFileExport,
    faFileMedicalAlt,
    faFolderOpen,
    faHome,
    faPalette,
    faParagraph,
    faTable,
    faTools,
    faUsers,
    faUsersCog
} from '@fortawesome/free-solid-svg-icons';

import {defineMessages} from "react-intl.macro";
import {useIntl} from "react-intl";

import UseFormatMessage from "../../helper/UseFormatMessage";
import pages from "../../config/pages";
import Token from "../../helper/Token";
import {useStore} from "../../store/useStore";

function Sidebar() {
    const { state } = useStore();
    const intl = useIntl();

    const messages = defineMessages({
        'sidebar_dashboard': {
            id: "sidebar.dashboard",
            defaultMessage: "Dashboard"
        },
        'sidebar_pins': {
            id: "sidebar.pins",
            defaultMessage: "Pins"
        },
        'sidebar_overview': {
            id: "sidebar.overview",
            defaultMessage: "Overview"
        },
        'sidebar_open': {
            id: "sidebar.open",
            defaultMessage: "Open"
        },
        'sidebar_invited': {
            id: "sidebar.invited",
            defaultMessage: "Invited"
        },
        'sidebar_started': {
            id: "sidebar.started",
            defaultMessage: "Started"
        },
        'sidebar_rating': {
            id: "sidebar.rating",
            defaultMessage: "Evaluation of OAC / Rate"
        },
        'sidebar_results': {
            id: "sidebar.results",
            defaultMessage: "Results"
        },
        'sidebar_project': {
            id: "sidebar.project",
            defaultMessage: "Project"
        },
        'sidebar_three_sixty_keys': {
            id: "sidebar.three_sixty_keys",
            defaultMessage: "360 degree keys"
        },
        'sidebar_create': {
            id: "sidebar.create",
            defaultMessage: "Create"
        },
        'sidebar_translations': {
            id: "sidebar.translations",
            defaultMessage: "Translations"
        },
        'sidebar_customer': {
            id: "sidebar.customer",
            defaultMessage: "Customer"
        },
        'sidebar_themes': {
            id: "sidebar.themes",
            defaultMessage: "Themes"
        },
        'sidebar_user_administration': {
            id: "sidebar.user_administration",
            defaultMessage: "User administration"
        },
        'sidebar_export': {
            id: "sidebar.export",
            defaultMessage: "Data export"
        },
        'sidebar_assessments': {
            id: "sidebar.assessments",
            defaultMessage: "Assessments"
        },
        'sidebar_modules': {
            id: "sidebar.modules",
            defaultMessage: "Modules"
        },
        'sidebar_administration': {
            id: "sidebar.administration",
            defaultMessage: "Administration"
        },
        'sidebar_countries': {
            id: "sidebar.countries",
            defaultMessage: "Countries"
        },
        'sidebar_languages': {
            id: "sidebar.languages",
            defaultMessage: "Languages"
        },
        'sidebar_norm_groups': {
            id: "sidebar.norm_groups",
            defaultMessage: "Norm groups"
        },
        'sidebar_constructs': {
            id: "sidebar.constructs",
            defaultMessage: "Constructs"
        },
        'sidebar_dimensions': {
            id: "sidebar.dimensions",
            defaultMessage: "Dimensions"
        },
        'sidebar_scales': {
            id: "sidebar.scales",
            defaultMessage: "Scales"
        },
        'sidebar_forced_choice_weighting': {
            id: "sidebar.forced_choice_weighting",
            defaultMessage: "Forced choice weighting"
        },
        'sidebar_report_dimension_average': {
            id: "sidebar.report_dimension_average",
            defaultMessage: "Report dimension averages"
        },
        'sidebar_invitations': {
            id: "sidebar.invitations",
            defaultMessage: "Invitations"
        },
        'sidebar_data_protection': {
            id: "sidebar.data_protection",
            defaultMessage: "Data protections"
        },
        'sidebar_pin_export': {
            id: "sidebar.pin_export",
            defaultMessage: "Pin export"
        },
        'sidebar_dimension_sorting': {
            id: "sidebar.dimension_sorting",
            defaultMessage: "Dimension Sorting"
        },
    });

    const appMenuItems = [
        {
            name: UseFormatMessage(intl, messages.sidebar_dashboard),
            page: pages.DASHBOARD,
            Icon: faHome,
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_pins),
            Icon: faTable,
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.PINS_CREATE,
                    roles: ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN'],
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_open),
                    page: pages.PINS_OPEN,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_invited),
                    page: pages.PINS_INVITED,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_started),
                    page: pages.PINS_STARTED,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_rating),
                    roles: ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN'],
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_results),
                    page: pages.PINS_RESULTS,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_project),
            Icon: faFolderOpen,
            roles: ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.PROJECT_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.PROJECT_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_three_sixty_keys),
            Icon: faFolderOpen,
            roles: ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.THREE_SIXTY_KEYS_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.THREE_SIXTY_KEYS_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_data_protection),
            Icon: faFolderOpen,
            roles: ['ROLE_SUPERADMIN', 'ROLE_NATIONALADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.DATA_PROTECTION_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.DATA_PROTECTION_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_invitations),
            Icon: faFolderOpen,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.INVITATIONS_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.INVITATIONS_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_translations),
            page: pages.TRANSLATIONS,
            Icon: faParagraph,
            roles: ['ROLE_SUPERADMIN'],
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_customer),
            Icon: faUsers,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.CUSTOMER_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.CUSTOMER_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_themes),
            Icon: faPalette,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.THEME_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.THEME_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_user_administration),
            Icon: faUsersCog,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.USER_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.USER_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_export),
            Icon: faFileExport,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_pin_export),
                    page: pages.EXPORT_PIN,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_assessments),
            Icon: faFileMedicalAlt,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.ASSESSMENT_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.ASSESSMENT_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_modules),
            Icon: faFileMedicalAlt,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_overview),
                    page: pages.MODULES_OVERVIEW,
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_create),
                    page: pages.MODULES_CREATE,
                },
            ]
        },
        {
            name: UseFormatMessage(intl, messages.sidebar_administration),
            Icon: faTools,
            roles: ['ROLE_SUPERADMIN'],
            items: [
                {
                    name: UseFormatMessage(intl, messages.sidebar_countries),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.COUNTRIES_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.COUNTRIES_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_languages),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.LANGUAGES_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.LANGUAGES_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_norm_groups),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.NORMGROUPS_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.NORMGROUPS_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_constructs),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.CONSTRUCTS_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.CONSTRUCTS_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_dimensions),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.DIMENSIONS_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.DIMENSIONS_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_scales),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.SCALES_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.SCALES_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_forced_choice_weighting),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.FORCED_CHOICE_WEIGHTING_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.FORCED_CHOICE_WEIGHTING_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_report_dimension_average),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.REPORT_DIMENSION_AVERAGES_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.REPORT_DIMENSION_AVERAGES_CREATE,
                        },
                    ]
                },
                {
                    name: UseFormatMessage(intl, messages.sidebar_dimension_sorting),
                    items: [
                        {
                            name: UseFormatMessage(intl, messages.sidebar_overview),
                            page: pages.DIMENSION_SORTING_OVERVIEW,
                        },
                        {
                            name: UseFormatMessage(intl, messages.sidebar_create),
                            page: pages.DIMENSION_SORTING_CREATE,
                        },
                    ]
                },
            ]
        }
    ];

    return (
        <List component="nav" className='sidebar' disablePadding>
            {/* <AppMenuItem {...appMenuItems[0]} /> */}
            {appMenuItems.map((item, index) => {
                if (!item.roles || Token.hasRoles(state.tokenData, item.roles)) {
                    return <SidebarItem {...item} key={index}/>
                }

                return null;
            })}
        </List>
    )
}

export default Sidebar;