import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {
    Box, Grid,
    TextField
} from "@material-ui/core";
import GlobalTrans from "../../../helper/GlobalTrans";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import GridElementCol from "./GridElement/GridElementCol";
import {useDrop} from "react-dnd";
import config from "../../../config/config";
import ModuleElementComponent from "../../../helper/ModuleElementComponent";
import GridElementContent from "./GridElement/GridElementContent";

const GridElement = (props) => {
    const elementName = GlobalTrans(props.title);

    const [colAmount, setColAmount] = useState(props.colAmount);

    const createNewColElementsObject = useCallback(() => {
        const newColElements = {};

        for (let i = 0; i < colAmount; i++) {
            newColElements[i] = props.colElements[i] || [];
        }

        return newColElements;
    }, [colAmount, props.colElements]);

    const saveModal = useCallback(() => {
        const data = {
            colAmount,
            colElements: createNewColElementsObject()
        };

        props.updateContent(props, data);
    }, [colAmount, createNewColElementsObject, props]);

    const pushColElement = useCallback((colIndex, element) => {
        const {element: foundElement} = props.findContent(element.id, element.grid, element.gridCol);

        if (element.grid) {
            // Remove from grid element
            props.removeContentFromGrid(element.id, element.grid, element.gridCol);
        } else {
            props.removeContent(element.id);
        }

        foundElement.grid = element.grid = props.id;
        foundElement.gridCol = element.gridCol = colIndex;

        let data = {
            colElements: {
                ...props.colElements,
                [colIndex]: [
                    ...props.colElements[colIndex],
                    foundElement
                ]
            }
        }

        props.updateContent(props, data);
    }, [props]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <TextField label={GlobalTrans('columns')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               setColAmount(e.target.value)
                           }}
                           value={colAmount}
                           required
                />
            </Box>
        </React.Fragment>
    );

    const getCols = useCallback(() => {
        const row = [];

        for (let i = 0; i < props.colAmount; i++) {
            row.push(
                <Grid item xs key={i}>
                    <GridElementCol
                        gridId={props.id}
                        index={i}
                        moveElementToGrid={pushColElement}
                    >
                        {
                            props.colElements[i].map((element) => (
                                <GridElementContent
                                    key={element.id}
                                    element={element}
                                    moveElementToGrid={pushColElement}
                                    findContent={props.findContent}
                                >
                                    <ModuleElementComponent
                                        element={element}
                                        elementType={element.elementType}
                                        updateContent={props.updateContent}
                                        findContent={props.findContent}
                                        removeContentFromGrid={props.removeContentFromGrid}
                                        addContent={props.addContent}
                                        removeContent={props.removeContent}
                                        checkboxDimension={props.checkboxDimension}
                                        dimensions={props.dimensions}
                                        updateAnswer={props.updateAnswer}
                                        removeAnswer={props.removeAnswer}
                                        getAnswer={props.getAnswer}
                                    />
                                </GridElementContent>
                            ))
                        }
                    </GridElementCol>
                </Grid>
            );
        }

        return row;
    }, [props.colAmount, props.colElements, props.id, props.removeContent, pushColElement, props.removeContentFromGrid, props.addContent, props.checkboxDimension, props.dimensions, props.findContent, props.updateContent, props.removeAnswer, props.updateAnswer, props.getAnswer]);

    const [, drop] = useDrop(() => ({
        accept: config.moduleDragItem,
        hover: (draggedElement) => {
            if (draggedElement.id !== props.id && !draggedElement.blocked && draggedElement.grid) {
                const {index: overIndex} = props.findContent(props.id);
                const gridId = draggedElement.grid;
                const gridCol = draggedElement.gridCol;

                draggedElement.grid = '';
                draggedElement.gridCol = '';

                const cloneDraggedElement = {...draggedElement};
                draggedElement.blocked = true;

                props.removeContentFromGrid(draggedElement.id, gridId, gridCol);

                props.addContent(cloneDraggedElement, overIndex + 1);

                draggedElement.blocked = false;
            }
        }
    }), [props]);

    return (
        <Box position={'relative'} pb={5}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                elementId={props.id}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translation={false}>
                {modalBody}
            </ModuleElementActions>

            <Grid container alignItems={"stretch"}>
                {getCols()}
            </Grid>

            <Box width={'100%'} height={40} zIndex={1} bottom={0} position={'absolute'} ref={drop}/>
        </Box>
    );
};

GridElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    findContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    addContent: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
};

export const GridElementConfig = {
    title: 'grid_element',
    colAmount: 2,
    colElements: {0: [], 1: []},
    validationType: 'grid',
}

export default GridElement;