import React, {useCallback} from "react";
import { useStore } from "../../store/useStore";
import { FormattedMessage } from 'react-intl.macro';

import Dropdown from "react-bootstrap/cjs/Dropdown";
import Button from "react-bootstrap/cjs/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCaretDown, faMoon, faSun} from '@fortawesome/free-solid-svg-icons';
import config from "../../config/config";

function Navigation() {
    const { state, dispatch } = useStore();

    const setLocale = useCallback(
        (locale) => {
            dispatch({type: "setLocale", payload: locale});
        },[
            dispatch
        ]);

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <Button ref={ref} className='languageSwitch' variant={"link"} onClick={(e) => {
                e.preventDefault();
                onClick(e);
            }}
        >
            {children} <FontAwesomeIcon icon={faCaretDown}/>
        </Button>
    ));

    const changeTheme = () => {
        if (state.theme === 'light') {
            localStorage.setItem('localStorageTheme', 'dark');
            dispatch({type: "setTheme", payload: 'dark'})
        } else {
            localStorage.setItem('localStorageTheme', 'light');
            dispatch({type: "setTheme", payload: 'light'})
        }
    };

    return (
        <React.Fragment>
            <Button variant={(state.theme === 'light') ? 'dark' : 'light'} onClick={changeTheme}>
                {
                    (
                    state.theme === 'light' &&
                    <FontAwesomeIcon icon={faMoon}/>
                    )
                    ||
                    <FontAwesomeIcon icon={faSun}/>
                }
            </Button>
            {
                state.languages &&
                <Dropdown>
                    <Dropdown.Toggle as={CustomToggle} id="dropdown-select-language">
                        <FormattedMessage
                            id="languages.select"
                            defaultMessage="Select language"
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item as="button" onClick={() => setLocale(config.defaultLocale)} className={state.locale === config.defaultLocale && 'active'}>
                            <FormattedMessage
                                id="languages.english"
                                defaultMessage="English"
                            />
                        </Dropdown.Item>
                        {
                            state.languages.map(language =>
                                <Dropdown.Item
                                    as="button"
                                    onClick={() => setLocale(language.code)}
                                    className={state.locale === language.code && 'active'}
                                    key={language.code}
                                >
                                    {language.name}
                                </Dropdown.Item>
                            )
                        }
                    </Dropdown.Menu>
                </Dropdown>
            }
        </React.Fragment>
    )
}

export default Navigation;