import React, {useCallback, useState} from 'react';
import {Box, TextField} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans from "../../../helper/GlobalTrans";
import TranslationsInput from "../../translations/TranslationsInput";
import {inputOnlyInteger} from "../../../helper/Helper";

const VideoElement = (props) => {
    const elementName = GlobalTrans(props.title);

    const [src, setSrc] = useState(props.src || '');
    const [width, setWidth] = useState(props.width || '');
    const [thumb, setThumb] = useState(props.thumb || '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            src,
            thumb,
            width,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, src, thumb, width, translations]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <TranslationsInput
                    field={'src'}
                    defaultValue={src}
                    setDefaultValue={setSrc}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('src')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('thumbnail')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               setThumb(e.target.value)
                           }}
                           value={thumb}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('width')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => inputOnlyInteger(e, setWidth)}
                           value={width}
                />
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            <video src={props.src} width={props.width} poster={props.thumb} controls>
                {GlobalTrans('browser_doesnt_support_video')}
            </video>
        </Box>
    );
};

VideoElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const VideoElementConfig = {
    title: 'video',
    src: '',
    thumb: '',
    width: '',
    translations: [],
}

export default VideoElement;