import React, {useCallback} from "react";
import ListItem from "@material-ui/core/ListItem";
import {useStore} from "../../store/useStore";

function SidebarItemComponent(props) {
    const { className, onClick, page, children } = props;
    const { state, dispatch } = useStore();

    const setPage = useCallback((page) => dispatch({ type: "setPage", payload: page }), [
        dispatch
    ]);

    // If link is not set return the orinary ListItem
    if (!page) {
        return (
            <ListItem
                button
                className={className + ((state.page === page) ? ' active' : '')}
                children={children}
                onClick={onClick}
            />
        )
    }

    // Return a LitItem with a link component
    return (
        <ListItem
            button
            className={className + ((state.page === page) ? ' active' : '')}
            children={children}
            onClick={() => {setPage(page)}}
        />
    )
}

export default SidebarItemComponent;