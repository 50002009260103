import Token from "../helper/Token";

const localStorageRefreshToken = 'localStorageRefreshToken';

function getRefreshToken() {
    return localStorage.getItem(localStorageRefreshToken) || '';
}

function deleteRefreshToken() {
    localStorage.removeItem(localStorageRefreshToken);

    return '';
}

export const tokenInitialState = {
    token: '',
    refreshToken: getRefreshToken(),
    tokenExpired: false,
    tokenData: {},
};

export const tokenActions = {
    setToken: (state, payload) => ({
        token: payload,
        tokenData: Token.getTokenData(payload),
    }),
    setTokens: (state, payload) => {
        localStorage.setItem(localStorageRefreshToken, payload.refreshToken);

        return {
            token: payload.token,
            refreshToken: payload.refreshToken,
            tokenData: Token.getTokenData(payload.token),
        }
    },
    deleteTokens: () => ({token: '', refreshToken: deleteRefreshToken(), tokenExpired: false, tokenData: {}}),
    setTokenExpired: (state, payload) => ({tokenExpired: payload})
};