import React, {useEffect} from "react";
import {useStore} from "./store/useStore";

import Footer from "./components/footer/Footer";
import Content from "./components/content/Content";

import {IntlProvider} from "react-intl";
import translations from "./translations/locales";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

function App() {
    const {state} = useStore();

    useEffect(() => {
        document.documentElement.className = '';
        document.documentElement.classList.add(`theme-${state.theme}`);
    }, [state.theme]);

    return (
        <IntlProvider
            locale={translations[state.locale] ? state.locale : 'en'}
            defaultLocale="en"
            key={state.locale}
            messages={translations[state.locale] || {}}
        >
            <DndProvider backend={HTML5Backend}>
                <div className="assessmentworks-admin">
                    <Content/>
                    <Footer/>
                </div>
            </DndProvider>
        </IntlProvider>
    );
}

export default App;
