import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {FormattedMessage} from "react-intl.macro";
import Spinner from "../spinner/Spinner";
import {Box, CardContent, Grid, InputLabel, MenuItem, Paper, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import Api from "../../helper/Api";
import {getTranslationFromTranslations, helperCatchErrors, selectIconComponent} from "../../helper/Helper";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import FormControl from "@material-ui/core/FormControl";
import config from "../../config/config";
import pages from "../../config/pages";

const ThreeSixtyKeysCreate = () => {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [name, setName] = useState(state.editForm.name || '');
    const [selectedProject, setSelectedProject] = useState(editForm?.project['@id'] || '');

    const [loadingProjects, setLoadingProjects] = useState(true);
    const [projects, setProjects] = useState([]);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const showSuccess = () => {
        window.scrollTo(0, 0);
        setNotificationError(false);
        setNotificationSuccess(true);
    };

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getProjects = useCallback(() => {
        Api.getApi(
            'projects',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setProjects(res.data['hydra:member']);
                }

                setLoadingProjects(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }

            cancelToken.cancel();
        };
    }, [timeoutWatcher, cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getProjects();
            setMounted(true);
        }
    }, [state.editForm, dispatch, editForm, mounted, getProjects])

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        const data = {
            name,
            project: selectedProject
        }

        if (editForm) {
            axios.put(config.apiUrl + `/three_sixty_keys/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'Content-Type': 'application/ld+json'}}))
                .then(res => {
                    if (res.data) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            dispatch({type: "setPage", payload: pages.THREE_SIXTY_KEYS_OVERVIEW});
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/three_sixty_keys`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        showSuccess();

                        setName('');
                        setSelectedProject('');
                    } else {
                        showError();
                    }
                })
                .catch(error => {
                    if (error.response && error.response.data['violations']) {
                        showError(error.response.data['violations'][0].message);
                    } else if (error.response) {
                        showError();
                    }
                });
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='three_sixty_keys.edit.headline' defaultMessage='Edit 360 degree key' />;
        }

        return <FormattedMessage id='three_sixty_keys.create.headline' defaultMessage='Create 360 degree key' />;
    };

    return (
        <Paper>
            <Spinner show={loadingProjects} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingProjects &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                            <Grid item>
                                <TranslationLanguages selectedLanguage={translationLanguage} setLanguage={setTranslationLanguage}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <form className={`three-sixty-keys--create-form`} onSubmit={sendSubmit}>
                        <Box mb={2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <TranslationsInput
                                        field={'name'}
                                        defaultValue={name}
                                        setDefaultValue={setName}
                                        translations={translations}
                                        language={translationLanguage}
                                        label={GlobalTransIntl('name', intl)}
                                        setTranslations={setTranslations}
                                        attributes={{
                                            required: true,
                                            id: `three-sixty-keys-create--name`
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <FormControl variant={'outlined'} required>
                                        <InputLabel id="three-sixty-keys-create--select-project">
                                            {GlobalTransIntl('project', intl)}
                                        </InputLabel>
                                        <Select
                                            labelId="three-sixty-keys-create--select-label"
                                            id="three-sixty-keys-create-select"
                                            value={selectedProject}
                                            label={GlobalTransIntl('project', intl)}
                                            onChange={(event) => setSelectedProject(event.target.value)}
                                            IconComponent={selectIconComponent}
                                        >
                                            {
                                                projects.map((project, index) => (
                                                    <MenuItem key={index} value={project['@id']}>
                                                        {getTranslationFromTranslations(project.translations, state.localeIRI, 'name', project.name)}
                                                    </MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>
                        <Button variant="primary" type="submit" className={`three-sixty-keys--create-submit`}>
                            {
                                (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                            }
                        </Button>
                    </form>
                </CardContent>
            }
        </Paper>
    );
};

export default ThreeSixtyKeysCreate;