import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {
    Box,
    List,
    ListItem,
    ListItemText,
    Typography
} from "@material-ui/core";
import GlobalTrans from "../../../helper/GlobalTrans";
import Form from "react-bootstrap/cjs/Form";
import ModuleElementDimension from "../../../helper/ModuleElementDimension";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";
import TranslationsInput from "../../translations/TranslationsInput";

const MultipleChoice = (props) => {
    const elementName = GlobalTrans(props.title);

    const [question, setQuestion] = useState(props.question);
    const [options, setOptions] = useState((props.options.length) ? props.options : ['']);
    const [dimension, setDimension] = useState(props.dimension || '');
    const [answer, setAnswer] = useState((props.answer !== undefined) ? props.answer : '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        const data = {
            question,
            options,
            translations,
        };

        if (!props.checkboxDimension) {
            data.dimension = dimension;
        }

        props.updateAnswer(props.id, answer);
        props.updateContent(props, data);
    }, [dimension, options, props, question, answer, translations]);

    const modalBody = (
        <React.Fragment>
            <ModuleElementDimension
                dimension={dimension}
                setDimension={setDimension}
                showDimension={!props.checkboxDimension}
                dimensions={props.dimensions}
            />

            <Form.Group controlId="name">
                <TranslationsInput
                    field={'question'}
                    defaultValue={question}
                    setDefaultValue={setQuestion}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('text')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Form.Group>

            <Box mb={2}>
                <Typography>{GlobalTrans('options')}</Typography>
            </Box>
            <ModuleElementOptions
                label={'option'}
                options={options}
                setOptions={setOptions}
                answer={answer}
                setAnswer={setAnswer}
                translations={translations}
                setTranslations={setTranslations}
                translationLanguage={translationLanguage}
                translationsField={'options'}
            />
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.question.length > 0 &&
                <Typography>{props.question}</Typography>
            }
            {
                props.options.length > 0 &&
                <List>
                    {
                        props.options.map((option, index) =>
                            <ListItem key={index}>
                                <ListItemText
                                    primary={option}
                                    primaryTypographyProps={{className: (index === answer) ? 'font-weight-bold' : ''}}
                                />
                            </ListItem>
                        )
                    }
                </List>
            }
        </Box>
    );
};

MultipleChoice.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
};

export const MultipleChoiceConfig = {
    title: 'multiple_choice',
    question: '',
    options: [],
    translations: [],
    validationType: 'option',
}

export default MultipleChoice;