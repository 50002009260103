import {useEffect, useState} from 'react';
import Api from "../../helper/Api";
import {useStore} from "../../store/useStore";
import axios from "axios";

const GetLanguages = () => {
    const {state, dispatch} = useStore();
    const [cancelToken] = useState(axios.CancelToken.source());

    useEffect(() => {
        if (!state.loadedLanguages) {
            Api.getLanguages(
                (res) => {
                    dispatch({
                        type: "setLoadedLanguages"
                    });
                    dispatch({
                        type: "setLanguages",
                        payload: res.data['hydra:member']
                    });
                },
                (error) => {
                    console.log(error);
                },
                '',
                cancelToken.token
            )
        }
    }, [state.token, state.languages, state.loadedLanguages, cancelToken, dispatch]);

    return null;
};

export default GetLanguages;