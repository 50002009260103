import React, {useEffect, useState} from "react";
import {FormattedMessage} from "react-intl.macro";
import Notifications from "../notifications/Notifications";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import {useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import {useStore} from "../../store/useStore";
import axios from "axios";
import config from "../../config/config";
import {
    inputOnlyLettersAndMinus,
    validateISOLanguageCode
} from "../../helper/Helper";
import TranslationsInput from "../translations/TranslationsInput";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";

function LanguagesCreate () {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [name, setName] = useState(state.editForm.name || '');
    const [code, setCode] = useState(state.editForm.code || '');
    const [url, setUrl] = useState(state.editForm.url || '');
    const [isPublic, setIsPublic] = useState(!!state.editForm.isPublic || false);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            setMounted(true);
        }
    }, [state.editForm, dispatch, editForm, mounted])

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        if (validateISOLanguageCode(code)) {
            const data = {
                name,
                code,
                isPublic,
                translations,
                url,
            };

            if (editForm) {
                axios.put(config.apiUrl + `/languages/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'Content-Type': 'application/ld+json'}}))
                    .then(res => {
                        window.scrollTo(0, 0);

                        if (res.data) {
                            setName('');
                            setCode('');
                            setIsPublic(false);
                            setNotificationSuccess(true);
                        } else {
                            setNotificationError(true);
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data['violations']) {
                            setNotificationErrorMessage(error.response.data['violations'][0].message);
                            setNotificationError(true);
                        } else if (error.response) {
                            setNotificationErrorMessage('');
                            setNotificationError(true);
                        }
                    });
            } else {
                axios.post(config.apiUrl + `/languages`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then(res => {
                        if (res.data) {
                            const newLanguages = [...state.languages];
                            newLanguages.push(res.data);
                            dispatch({
                                type: "setLanguages",
                                payload: newLanguages
                            });
                            setName('');
                            setCode('');
                            setUrl('');
                            setIsPublic(false);
                            setNotificationSuccess(true);
                        } else {
                            setNotificationError(true);
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.data['violations']) {
                            setNotificationErrorMessage(error.response.data['violations'][0].message);
                            setNotificationError(true);
                        } else if (error.response) {
                            setNotificationErrorMessage('');
                            setNotificationError(true);
                        }
                    });
            }
        } else {
            setNotificationErrorMessage('error_invalid_language_code');
            setNotificationError(true);
        }
    };

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='languages.edit.headline' defaultMessage='Edit language' />;
        }

        return <FormattedMessage id='languages.create.headline' defaultMessage='Create language' />;
    };

    return (
        <React.Fragment>
            <Notifications success={notificationSuccess}
                           error={notificationError}
                           errorMessage={GlobalTrans(notificationErrorMessage)}
            />
            <Row>
                <Col xs={12} xl={6}>
                    <Paper className={`languages--create-paper`}>
                        <CardContent>
                            <Box mb={2}>
                                <Grid container spacing={2}>
                                    <Grid item xs>
                                        <h1>
                                            <Headline/>
                                        </h1>
                                    </Grid>
                                    <Grid item>
                                        <TranslationLanguages selectedLanguage={translationLanguage} setLanguage={setTranslationLanguage}/>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Form className={`languages--create-form`} onSubmit={sendSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={isPublic}
                                                               onChange={() => setIsPublic(prev => !prev)}
                                                               name={GlobalTransIntl('is_public', intl)}
                                                               id={'languages-create--public'}/>}
                                            label={GlobalTransIntl('is_public', intl)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TranslationsInput
                                            field={'name'}
                                            defaultValue={name}
                                            setDefaultValue={setName}
                                            translations={translations}
                                            language={translationLanguage}
                                            label={GlobalTransIntl('name', intl)}
                                            setTranslations={setTranslations}
                                            attributes={{
                                                required: true,
                                                id: `languages-create--name`
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label={GlobalTransIntl('language_code', intl)}
                                                   id={`languages-create--code`}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => {
                                                       inputOnlyLettersAndMinus(e, setCode)
                                                   }}
                                                   value={code}
                                                   required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField label={GlobalTransIntl('domain', intl)}
                                                   id={`languages-create--url`}
                                                   autoComplete={'off'}
                                                   variant="outlined"
                                                   onChange={(e) => setUrl(e.target.value)}
                                                   value={url}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={2}>
                                    <Button variant="primary" type="submit" className={`languages--create-submit`}>
                                        {
                                            (editForm) ? GlobalTrans('button_save') : GlobalTrans('button_create')
                                        }
                                    </Button>
                                </Box>
                            </Form>
                        </CardContent>
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default LanguagesCreate;