import React from 'react';
import {AppBar, CardContent, Tabs, Tab} from "@material-ui/core";
import TabPanel from "./TabPanel";
import TabPanelContent from "./TabPanelContent";
import PropTypes from "prop-types";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";

const ModulesContent = (props) => {
    const intl = useIntl();

    return (
        <CardContent>
            <AppBar position={'static'}>
                <Tabs
                    value={props.pageIndex}
                    onChange={(event, newValue) => props.setPageIndex(newValue)}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {
                        props.content.map((tab, index) =>
                            <Tab key={index} label={GlobalTransIntl('page', intl) + ' ' + (index + 1)}/>
                        )
                    }
                    <Tab label={'+'} onClick={props.addNewTab}/>
                </Tabs>
            </AppBar>
            {
                props.content.map((tab, key) => {
                    return (
                        <TabPanel
                            key={tab.id}
                            index={key}
                            value={props.pageIndex}
                            addContent={props.addContent}
                            checkboxTimer={props.checkboxTimer}
                            updateTabResponseTime={props.updateTabResponseTime}
                            responseTime={tab.responseTime}
                        >
                            {
                                tab.elements.map((element, index) => (
                                    <TabPanelContent
                                        key={element.id}
                                        element={element}
                                        elementIndex={index}
                                        findContent={props.findContent}
                                        moveContent={props.moveContent}
                                        updateContent={props.updateContent}
                                        removeContent={props.removeContent}
                                        checkboxDimension={props.checkboxDimension}
                                        dimensions={props.dimensions}
                                        removeContentFromGrid={props.removeContentFromGrid}
                                        addContent={props.addContent}
                                        updateAnswer={props.updateAnswer}
                                        removeAnswer={props.removeAnswer}
                                        getAnswer={props.getAnswer}
                                    />
                                ))
                            }
                        </TabPanel>
                    );
                })
            }
        </CardContent>
    );
};

ModulesContent.propTypes = {
    content: PropTypes.array.isRequired,
    addNewTab: PropTypes.func.isRequired,
    addContent: PropTypes.func.isRequired,
    pageIndex: PropTypes.number.isRequired,
    setPageIndex: PropTypes.func.isRequired,
    moveContent: PropTypes.func.isRequired,
    findContent: PropTypes.func.isRequired,
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    dimensions: PropTypes.array.isRequired,
    checkboxTimer: PropTypes.bool,
    checkboxDimension: PropTypes.bool,
    updateTabResponseTime: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
    getAnswer: PropTypes.func.isRequired,
};

export default ModulesContent;