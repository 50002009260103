import React from 'react';
import PropTypes from "prop-types";
import {useDrag, useDrop} from "react-dnd";
import config from "../../config/config";
import {Box} from "@material-ui/core";
import ModuleElementComponent from "../../helper/ModuleElementComponent";

const TabPanelContent = (props) => {
    const originalIndex = props.findContent(props.element.id).index;

    const [, drag] = useDrag(
        () => ({
            type: config.moduleDragItem,
            item: {...props.element, originalIndex},
            end: (item, monitor) => {
                const {id: droppedId, originalIndex} = item;
                const didDrop = monitor.didDrop();

                if (!didDrop) {
                    if (item.grid) {
                        props.removeContentFromGrid(item.id, item.grid, item.gridCol);

                        item.grid = '';
                        item.gridCol = '';
                        props.addContent(item, originalIndex);
                    } else {
                        props.moveContent(droppedId, originalIndex);
                    }
                }
            }
        }),
        [props]
    );

    const [, drop] = useDrop(() => ({
        accept: config.moduleDragItem,
        hover: (draggedElement) => {
            if (draggedElement.id !== props.element.id && !draggedElement.blocked) {
                const {index: overIndex} = props.findContent(props.element.id);

                if (draggedElement.grid) {
                    const gridId = draggedElement.grid;
                    const gridCol = draggedElement.gridCol;

                    draggedElement.grid = '';
                    draggedElement.gridCol = '';

                    const cloneDraggedElement = {...draggedElement};
                    draggedElement.blocked = true;

                    props.removeContentFromGrid(draggedElement.id, gridId, gridCol);

                    props.addContent(cloneDraggedElement, overIndex);

                    draggedElement.blocked = false;
                } else {
                    props.moveContent(draggedElement.id, overIndex);
                }
            }
        }
    }), [props]);

    return (
        <Box ref={drag} position={'relative'}>
            <Box width={'100%'} height={50} zIndex={1} position={'absolute'} ref={drop}/>
            <Box p={1} mb={1} className={'theme-border'}>
                <ModuleElementComponent
                    element={props.element}
                    elementType={props.element.elementType}
                    updateContent={props.updateContent}
                    findContent={props.findContent}
                    removeContentFromGrid={props.removeContentFromGrid}
                    addContent={props.addContent}
                    removeContent={props.removeContent}
                    checkboxDimension={props.checkboxDimension}
                    dimensions={props.dimensions}
                    updateAnswer={props.updateAnswer}
                    removeAnswer={props.removeAnswer}
                    getAnswer={props.getAnswer}
                />
            </Box>
        </Box>
    );
};

TabPanelContent.propTypes = {
    element: PropTypes.object.isRequired,
    findContent: PropTypes.func.isRequired,
    moveContent: PropTypes.func.isRequired,
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    addContent: PropTypes.func.isRequired,
    elementIndex: PropTypes.number.isRequired,
    dimensions: PropTypes.array.isRequired,
    checkboxDimension: PropTypes.bool,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
    getAnswer: PropTypes.func.isRequired,
};

TabPanelContent.defaultProps = {
    checkboxDimension: false,
}

export default TabPanelContent;