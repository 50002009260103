import React, {useCallback, useState} from 'react';
import {Box} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import GlobalTrans from "../../../helper/GlobalTrans";
import DOMPurify from "dompurify";
import TranslationsInput from "../../translations/TranslationsInput";

const HtmlElement = (props) => {
    const elementName = GlobalTrans(props.title);

    const [content, setContent] = useState(props.content || '');

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            content,
            translations,
        };

        // update the data
        props.updateContent(props, data);
    }, [props, content, translations]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <TranslationsInput
                    field={'content'}
                    defaultValue={content}
                    setDefaultValue={setContent}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('text')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                        multiline: true,
                        minRows: 25,
                    }}
                />
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.content)}}/>
        </Box>
    );
};

HtmlElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const HtmlElementConfig = {
    title: 'html',
    content: '',
    translations: [],
}

export default HtmlElement;