import React, {useCallback, useEffect, useState} from 'react';
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import {Box, Button} from "@material-ui/core";
import Spinner from "../spinner/Spinner";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useIntl} from "react-intl";
import Notifications from "../notifications/Notifications";
import {helperCatchErrors} from "../../helper/Helper";
import {FormattedMessage} from "react-intl.macro";
import PropTypes from "prop-types";

const ResultPdf = (props) => {
    const {state} = useStore();
    const intl = useIntl();

    const pdfLocale = props.locale.toLowerCase();

    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);

    const [dlink, setDlink] = useState(null);
    const [creatingLink, setCreatingLink] = useState(true);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {

        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const triggerDownload = useCallback(() => {
        dlink.click();
    }, [dlink]);

    const getPdfFile = useCallback((filename) => {
        axios.get(config.apiUrl + `${props.pdfUrl}/${pdfLocale}/${props.pinId}`, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            responseType: 'blob'
        }))
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                link.href = url;
                link.download = filename;

                document.body.appendChild(link);
                setDlink(link);

                const blob = new Blob(
                    [response.data],
                    {type: 'application/pdf'}
                );
                window.open(window.URL.createObjectURL(blob));

                setCreatingLink(false);
            })
            .catch(catchErrors);
    }, [cancelToken.token, state.token, pdfLocale, props.pinId, props.pdfUrl, catchErrors]);

    const getPdf = useCallback(() => {
        axios.get(config.apiUrl + `/result/pdf/name/${pdfLocale}/${props.pinId}`, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token
        }))
            .then(response => {
                getPdfFile(response.data.filename);
            });
    }, [cancelToken.token, state.token, getPdfFile, pdfLocale, props.pinId]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            getPdf();
        }
    }, [mounted, getPdf]);

    return (
        <React.Fragment>
            <h2 className={'text-align-center'}>
                <FormattedMessage id='pin.results.modal.headline' defaultMessage='Result from'/><br/> {props.pinName}
            </h2>
            <Spinner show={creatingLink && !notificationError} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                    cols={{
                        xs: 12,
                        lg: 12
                    }}
                />
            </Box>
            {
                !creatingLink &&
                <Box p={5} className={'text-align-center'}>
                    <Button variant="contained" color="primary" onClick={triggerDownload}>
                        {GlobalTransIntl('download_pdf', intl)}
                    </Button>
                </Box>
            }
        </React.Fragment>
    );
};

ResultPdf.propTypes = {
    pinId: PropTypes.number.isRequired,
    locale: PropTypes.string,
    pdfUrl: PropTypes.string,
}

ResultPdf.defaultProps = {
    locale: config.defaultLocale,
    pdfUrl: '/result/pdf',
}

export default ResultPdf;