import React from 'react';
import {Box, InputLabel, MenuItem, Select} from "@material-ui/core";
import {GlobalTransIntl} from "./GlobalTrans";
import FormControl from "@material-ui/core/FormControl";
import {selectIconComponent} from "./Helper";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";

const ModuleElementDimension = (props) => {
    const intl = useIntl();

    if (!props.showDimension) {
        return null;
    }

    return (
        <Box mb={2} display={'inline-block'} width={'100%'}>
            <FormControl variant={'outlined'} required>
                <InputLabel id="module-dimensions-select-label">
                    {GlobalTransIntl('dimensions', intl)}
                </InputLabel>
                <Select
                    labelId="module-dimensions-select-label"
                    value={props.dimension}
                    label={GlobalTransIntl('dimensions', intl)}
                    onChange={(event) => props.setDimension(event.target.value)}
                    IconComponent={selectIconComponent}
                >
                    {
                        props.dimensions.map((dimension, index) => (
                            <MenuItem key={index} value={dimension.id}>{dimension.name}</MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    );
};

ModuleElementDimension.propTypes = {
    dimension: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    setDimension: PropTypes.func.isRequired,
    dimensions: PropTypes.array.isRequired,
    showDimension: PropTypes.bool
};

ModuleElementDimension.defaultProps = {
    showDimension: true,
}

export default ModuleElementDimension;