import React from "react";
import OverviewWithName from "../overviewWithName/OverviewWithName";
import pages from "../../config/pages";

function NormgroupsOverview () {
    return (
        <OverviewWithName
            apiUrl={'normgroups'}
            class={'normgroups'}
            editPage={pages.NORMGROUPS_CREATE}
            hasTranslation={true}
        />
    );
}

export default NormgroupsOverview;