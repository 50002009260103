import React from "react";
import OverviewWithName from "../overviewWithName/OverviewWithName";
import pages from "../../config/pages";

function CustomerOverview () {

    return (
        <OverviewWithName apiUrl={'customers'} class={'customer'} editPage={pages.CUSTOMER_CREATE}/>
    );
}

export default CustomerOverview;