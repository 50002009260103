import React, {useCallback, useState} from 'react';
import GlobalTrans from "../../../helper/GlobalTrans";
import {Box, TextField} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import GridElementCol from "./GridElement/GridElementCol";
import GridElementContent from "./GridElement/GridElementContent";
import ModuleElementComponent from "../../../helper/ModuleElementComponent";
import {useDrop} from "react-dnd";
import config from "../../../config/config";
import PropTypes from "prop-types";

const MailTasksElement = (props) => {
    const elementName = GlobalTrans(props.title);

    const [taskAmount, setTaskAmount] = useState(props.taskAmount);

    const createNewColElementsObject = useCallback(() => {
        const newColElements = {};

        for (let i = 0; i < taskAmount; i++) {
            newColElements[i] = props.colElements[i] || [];
        }

        return newColElements;
    }, [taskAmount, props.colElements]);

    const saveModal = useCallback(() => {
        const data = {
            taskAmount,
            colElements: createNewColElementsObject()
        };

        props.updateContent(props, data);
    }, [taskAmount, createNewColElementsObject, props]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <TextField label={GlobalTrans('tasks')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               setTaskAmount(e.target.value)
                           }}
                           value={taskAmount}
                           required
                />
            </Box>
        </React.Fragment>
    );

    const pushTaskElement = useCallback((colIndex, element) => {
        const {element: foundElement} = props.findContent(element.id, element.grid, element.gridCol);

        if (element.grid) {
            // Remove from grid element
            props.removeContentFromGrid(element.id, element.grid, element.gridCol);
        } else {
            props.removeContent(element.id);
        }

        foundElement.grid = element.grid = props.id;
        foundElement.gridCol = element.gridCol = colIndex;

        let data = {
            colElements: {
                ...props.colElements,
                [colIndex]: [
                    ...props.colElements[colIndex],
                    foundElement
                ]
            }
        }

        props.updateContent(props, data);
    }, [props]);

    const getCols = useCallback(() => {
        const row = [];

        for (let i = 0; i < props.taskAmount; i++) {
            row.push(
                <Box mb={2} key={i}>
                    <GridElementCol
                        gridId={props.id}
                        index={i}
                        moveElementToGrid={pushTaskElement}
                    >
                        {
                            props.colElements[i].map((element) => (
                                <GridElementContent
                                    key={element.id}
                                    element={element}
                                    moveElementToGrid={pushTaskElement}
                                    findContent={props.findContent}
                                >
                                    <ModuleElementComponent
                                        element={element}
                                        elementType={element.elementType}
                                        updateContent={props.updateContent}
                                        findContent={props.findContent}
                                        removeContentFromGrid={props.removeContentFromGrid}
                                        addContent={props.addContent}
                                        removeContent={props.removeContent}
                                        checkboxDimension={props.checkboxDimension}
                                        dimensions={props.dimensions}
                                        updateAnswer={props.updateAnswer}
                                        removeAnswer={props.removeAnswer}
                                        getAnswer={props.getAnswer}
                                    />
                                </GridElementContent>
                            ))
                        }
                    </GridElementCol>
                </Box>
            );
        }

        return row;
    }, [props.taskAmount, props.colElements, props.id, props.removeContent, pushTaskElement, props.removeContentFromGrid, props.addContent, props.checkboxDimension, props.dimensions, props.findContent, props.updateContent, props.removeAnswer, props.updateAnswer, props.getAnswer]);

    const [, drop] = useDrop(() => ({
        accept: config.moduleDragItem,
        hover: (draggedElement) => {
            if (draggedElement.id !== props.id && !draggedElement.blocked && draggedElement.grid) {
                const {index: overIndex} = props.findContent(props.id);
                const gridId = draggedElement.grid;
                const gridCol = draggedElement.gridCol;

                draggedElement.grid = '';
                draggedElement.gridCol = '';

                const cloneDraggedElement = {...draggedElement};
                draggedElement.blocked = true;

                props.removeContentFromGrid(draggedElement.id, gridId, gridCol);

                props.addContent(cloneDraggedElement, overIndex + 1);

                draggedElement.blocked = false;
            }
        }
    }), [props]);

    return (
        <Box position={'relative'} pb={5}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                elementId={props.id}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translation={false}>
                {modalBody}
            </ModuleElementActions>

            {getCols()}

            <Box width={'100%'} height={40} zIndex={1} bottom={0} position={'absolute'} ref={drop}/>
        </Box>
    );
};

MailTasksElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    findContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    addContent: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
};

export const MailTasksConfig = {
    title: 'mail_tasks',
    taskAmount: 2,
    colElements: {0: [], 1: []},
    validationType: 'mailTasks',
}

export default MailTasksElement;