import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import ModuleElementDimension from "../../../helper/ModuleElementDimension";
import Form from "react-bootstrap/cjs/Form";
import TranslationsInput from "../../translations/TranslationsInput";
import {Box, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Typography} from "@material-ui/core";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import FormControl from "@material-ui/core/FormControl";
import {selectIconComponent} from "../../../helper/Helper";
import {useIntl} from "react-intl";

const RightAndWrongChoiceElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTrans(props.title);

    const [question, setQuestion] = useState(props.question);
    const [options, setOptions] = useState((props.options.length) ? props.options : ['','']);
    const [dimension, setDimension] = useState(props.dimension || '');
    const [answer, setAnswer] = useState((props.answer !== undefined) ? props.answer : {right: '', wrong: ''});

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        const data = {
            question,
            options,
            translations,
        };

        if (!props.checkboxDimension) {
            data.dimension = dimension;
        }

        props.updateAnswer(props.id, answer);
        props.updateContent(props, data);
    }, [dimension, options, props, question, answer, translations]);

    const updateAnswer = (index, value) => {
        setAnswer(prev => ({
            ...prev,
            [index]: value
        }))
    }

    const removeOption = (removeIndex) => {
        if (answer.wrong === removeIndex) {
            updateAnswer('wrong', '');
        } else if (answer.right === removeIndex) {
            updateAnswer('right', '');
        }
    }

    const AnswerSelect = (
        <React.Fragment>
            <Box mb={2} display={'inline-block'} width={'100%'}>
                <FormControl variant={'outlined'} required>
                    <InputLabel id="module-answer-select-label">
                        {GlobalTransIntl('correct_answer', intl)}
                    </InputLabel>
                    <Select
                        labelId="module-answer-select-label"
                        value={answer.right}
                        label={GlobalTransIntl('correct_answer', intl)}
                        onChange={(event) => updateAnswer('right', event.target.value)}
                        IconComponent={selectIconComponent}
                    >
                        {
                            options.map((option, index) => (
                                option && <MenuItem key={index} value={index} disabled={index === answer.wrong}>{option}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            <Box mb={2} display={'inline-block'} width={'100%'}>
                <FormControl variant={'outlined'} required>
                    <InputLabel id="module-answer-wrong-select-label">
                        {GlobalTransIntl('wrong_answer', intl)}
                    </InputLabel>
                    <Select
                        labelId="module-answer-wrong-select-label"
                        value={answer.wrong}
                        label={GlobalTransIntl('wrong_answer', intl)}
                        onChange={(event) => updateAnswer('wrong', event.target.value)}
                        IconComponent={selectIconComponent}
                    >
                        {
                            options.map((option, index) => (
                                option && <MenuItem key={index} value={index} disabled={index === answer.right}>{option}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
        </React.Fragment>
    );

    const modalBody = (
        <React.Fragment>
            <ModuleElementDimension
                dimension={dimension}
                setDimension={setDimension}
                showDimension={!props.checkboxDimension}
                dimensions={props.dimensions}
            />

            <Form.Group controlId="name">
                <TranslationsInput
                    field={'question'}
                    defaultValue={question}
                    setDefaultValue={setQuestion}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('text')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Form.Group>

            <Box mb={2}>
                <Typography>{GlobalTrans('options')}</Typography>
            </Box>
            <ModuleElementOptions
                label={'option'}
                options={options}
                setOptions={setOptions}
                removeOption={removeOption}
                answer={answer}
                setAnswer={setAnswer}
                translations={translations}
                setTranslations={setTranslations}
                translationLanguage={translationLanguage}
                translationsField={'options'}
                selectAnswer={AnswerSelect}
                min={2}
            />
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.question.length > 0 &&
                <Typography>{props.question}</Typography>
            }
            {
                props.options.length > 0 &&
                <List>
                    {
                        props.options.map((option, index) =>
                            <ListItem key={index}>
                                <ListItemText
                                    primary={option}
                                    primaryTypographyProps={{className: (index === answer.right || index === answer.wrong) ? 'font-weight-bold' : ''}}
                                />
                            </ListItem>
                        )
                    }
                </List>
            }
        </Box>
    );
};

RightAndWrongChoiceElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    checkboxDimension: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
    updateAnswer: PropTypes.func.isRequired,
    removeAnswer: PropTypes.func.isRequired,
};

export const RightAndWrongChoiceConfig = {
    title: 'right_and_wrong_choice',
    question: '',
    options: [],
    translations: [],
    validationType: 'rightAndWrong',
}

export default RightAndWrongChoiceElement;