import React, {createContext, useReducer, useContext} from "react";
import PropTypes from "prop-types";

import {pageInitialState, pageActions} from "../actions/page";
import {localeInitialState, localeActions} from "../actions/locale";
import {tokenInitialState, tokenActions} from "../actions/token";
import {resetActions} from "../actions/reset";
import {themeActions, themeInitialState} from "../actions/theme";
import {projectActions, projectInitialState} from "../actions/project";
import {resultDetailsActions, resultDetailsState} from "../actions/resultDetails";
import {editFormActions, editFormInitialState} from "../actions/editForm";
import {projectsActions, projectsInitialState} from "../actions/projects";
import {refresherActions, refresherInitialState} from "../actions/refresher";
import {languagesActions, languagesInitialState} from "../actions/languages";
import {pinResultsActions, pinResultsInitialState} from "../actions/pinResults";

export const initialState = {
    ...pageInitialState,
    ...localeInitialState,
    ...tokenInitialState,
    ...themeInitialState,
    ...projectInitialState,
    ...projectsInitialState,
    ...resultDetailsState,
    ...editFormInitialState,
    ...refresherInitialState,
    ...languagesInitialState,
    ...pinResultsInitialState,
};

const StoreContext = createContext(initialState);

const Actions = {
    ...pageActions,
    ...localeActions,
    ...tokenActions,
    ...resetActions,
    ...themeActions,
    ...projectActions,
    ...projectsActions,
    ...resultDetailsActions,
    ...editFormActions,
    ...refresherActions,
    ...languagesActions,
    ...pinResultsActions,
};

const reducer = (state, action) => {
    const {type, payload} = action;
    const act = Actions[type];
    const update = act(state, payload);
    return {...state, ...update};
};

export const StoreProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    return (
        <StoreContext.Provider value={{state, dispatch}}>
            {children}
        </StoreContext.Provider>
    );
};

StoreProvider.propTypes = {
    children: PropTypes.node
};

export const useStore = () => {
    const {state, dispatch} = useContext(StoreContext);
    return {state, dispatch};
};
