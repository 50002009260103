import React, {useEffect, useMemo, useState} from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobe} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {useStore} from "../../store/useStore";
import pages from "../../config/pages";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import {selectIconComponent} from "../../helper/Helper";
import axios from "axios";
import config from "../../config/config";
import {MenuItem} from "@material-ui/core";
import Spinner from "../spinner/Spinner";
import {useIntl} from "react-intl";

function Translations () {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    // states
    const [mounted, setMounted] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());
    const [loadingData, setLoadingData] = useState(true);
    const [keyLanguage, setKeyLanguage] = useState('');
    const [languages, setLanguages] = useState([]);
    const [columns, setColumns] = useState([]);

    const defaultColumns = useMemo(() => [
        { field: 'value', headerName: GlobalTransIntl('field_key', intl), width: 500 },
    ], [intl]);

    useEffect(() => {
        const localColumns = [...defaultColumns];

        setColumns(localColumns);

        if (keyLanguage) {
            const filterItem = languages.filter((item) => {
                return (item['@id'] === keyLanguage);
            });

            if (filterItem.length) {
                localColumns.push({
                    field: 'language', headerName: filterItem[0].name, width: 600,
                    valueGetter: (params) => {
                        let rowLanguage = params.row.i18ns.find(item => item.language === keyLanguage);

                        if (rowLanguage) {
                            return rowLanguage.value;
                        }

                        return '';
                    }
                });

                setColumns(localColumns);
            }
        }

        setColumns(localColumns);
    }, [keyLanguage, defaultColumns, languages]);

    useEffect(() => {
        if (!mounted) {
            axios.get(config.apiUrl + `/languages`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        setLanguages(res.data['hydra:member']);
                        setLoadingData(false);
                    }
                })
                .catch(error => {
                    if (axios.isCancel(error)) {

                    } else if (error.response) {
                        console.log(error.response);
                    }
                });

            setMounted(true);
        }
    }, [mounted, state.token, cancelToken]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.TRANSLATION});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={()=> onEdit(props)} aria-label={GlobalTransIntl('title_translations', intl)} title={GlobalTransIntl('title_translations', intl)}>
                <FontAwesomeIcon icon={faGlobe} size={"xs"}/>
            </IconButton>
        )
    };

    if (loadingData) {
        return (
            <Paper>
                <Spinner show={true} rowClass={'p-5'} />
            </Paper>
        );
    }

    return (
        <Paper>
            <div className="toolbar pb-4">
                <Row>
                    <Col xs={12} sm={3}>
                        <FormControl variant={'outlined'} id={`translations--filter-language`}>
                            <InputLabel>
                                {GlobalTransIntl('language', intl)}
                            </InputLabel>
                            <Select
                                labelId="translations--language-select"
                                value={keyLanguage}
                                onChange={(e) => {setKeyLanguage(e.target.value);}}
                                label={GlobalTransIntl('language', intl)}
                                IconComponent={selectIconComponent}
                            >
                                {
                                    languages.map((item, key) =>
                                        <MenuItem value={item['@id']} key={key} data-name={item.name}>{item.name}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    </Col>
                </Row>
            </div>
            <LoadingDataGrid
                columns={columns}
                apiUrl={'translation_keys'}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'value',
                        label: GlobalTransIntl('field_key', intl)
                    }
                ]}
                columnActions={columnActions}
                deleteAction={false}
            />
        </Paper>
    );
}

export default Translations;