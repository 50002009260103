import React from 'react';
import PropTypes from 'prop-types';
import {useStore} from "../../store/useStore";
import FormControl from "@material-ui/core/FormControl";
import {Box, InputLabel, MenuItem, Select} from "@material-ui/core";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {selectIconComponent} from "../../helper/Helper";
import {useIntl} from "react-intl";

const TranslationLanguages = (props) => {
    const {state} = useStore();
    const intl = useIntl();

    return (
        <Box sx={{minWidth: 200}}>
            <FormControl variant={'outlined'}>
                <InputLabel id="form-languages--select-label">{GlobalTransIntl('translation_language', intl)}</InputLabel>
                <Select
                    labelId="form-languages--select-label"
                    value={props.selectedLanguage}
                    label={GlobalTransIntl('translation_language', intl)}
                    onChange={(event) => props.setLanguage(event.target.value)}
                    IconComponent={selectIconComponent}
                >
                    <MenuItem value="">
                        {GlobalTransIntl('translation_default', intl)}
                    </MenuItem>
                    {
                        state.languages.map((language) => (
                            <MenuItem key={language.code} value={language['@id']}>
                                {language.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
        </Box>
    );
};

TranslationLanguages.propTypes = {
    selectedLanguage: PropTypes.string.isRequired,
    setLanguage: PropTypes.func.isRequired,
}

export default TranslationLanguages;