import React from "react";
import OverviewWithName from "../overviewWithName/OverviewWithName";
import pages from "../../config/pages";

function CountriesOverview () {
    return (
        <OverviewWithName
            apiUrl={'countries'}
            class={'countries'}
            editPage={pages.COUNTRIES_CREATE}
            hasTranslation={true}
        />
    )
}

export default CountriesOverview;