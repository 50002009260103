import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {Alert} from "react-bootstrap";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import React from "react";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";

function Notifications(props) {
    const intl = useIntl();

    if (!props.success && !props.delete && !props.error && !props.info) {
        return null;
    }

    return (
        <React.Fragment>
            <Row>
                <Col {...props.cols}>
                    <Alert variant='success' show={props.success}>
                        {
                            props.successMessage || GlobalTransIntl('notification_success', intl)
                        }
                    </Alert>
                    <Alert variant='success' show={props.delete}>
                        {
                            props.deleteMessage || GlobalTransIntl('notification_delete_success', intl)
                        }
                    </Alert>
                    <Alert variant='danger' show={props.error}>
                        {
                            props.errorMessage || GlobalTransIntl('notification_error', intl)
                        }
                    </Alert>
                    <Alert variant='info' show={props.info}>
                        {
                            props.infoMessage || ''
                        }
                    </Alert>
                </Col>
            </Row>
        </React.Fragment>
    );
}

Notifications.propTypes = {
    success: PropTypes.bool,
    delete: PropTypes.bool,
    error: PropTypes.bool,
    info: PropTypes.bool,
    successMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    deleteMessage: PropTypes.string,
    errorMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    infoMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array
    ]),
    cols: PropTypes.object,
};

Notifications.defaultProps = {
    success: false,
    delete: false,
    error: false,
    info: false,
    cols: {
        xs: 12,
        lg: 6
    }
}

export default Notifications;