import React, {useCallback} from 'react';
import {
    Box,
    Button,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid
} from "@material-ui/core";

import elements from "./elements";
import DraggableElement from "./DraggableElement";
import PropTypes from "prop-types";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";

const ModulesDragables = (props) => {
    const intl = useIntl();

    const [dialogOpen, setDialogOpen] = React.useState(false);

    const openDialog = useCallback(() => {
        setDialogOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const handleRemoveTab = useCallback(() => {
        props.removeTab();
        closeDialog();
    }, [props, closeDialog]);

    const getCurrentPageName = useCallback(() => {
        return GlobalTransIntl('page', intl) + ' ' + (props.pageIndex + 1);
    }, [props.pageIndex, intl]);

    const triggerSubmitForFormRef = useCallback(() => {
        props.formButtonRef.current.click();
    }, [props.formButtonRef]);

    const dialog = (
        <Dialog
            open={dialogOpen}
            onClose={closeDialog}
            aria-describedby={GlobalTransIntl('dialog_remove', intl)}
        >
            <Box p={1}>
                <DialogContent>
                    <DialogContentText>
                        {GlobalTransIntl('dialog_remove_placeholder', intl, {placeholder: getCurrentPageName()})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialog} variant={"contained"}>
                        {GlobalTransIntl('no', intl)}
                    </Button>
                    <Button onClick={handleRemoveTab} color="primary" variant={"contained"} autoFocus>
                        {GlobalTransIntl('yes', intl)}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

    return (
        <CardContent>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        <Button
                            variant={"contained"}
                            color={"primary"}
                            type={"submit"}
                            fullWidth
                            onClick={triggerSubmitForFormRef}
                        >
                            {GlobalTrans('button_save_module')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Button
                            variant={"contained"}
                            color={"secondary"}
                            startIcon={<FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>}
                            onClick={openDialog}
                            fullWidth
                        >
                            {GlobalTrans('button_delete_active_tab')}
                        </Button>
                    </Grid>
                </Grid>
            </Box>
            <h2>{GlobalTrans('elements')}</h2>
            {
                elements.map((element, key) => {
                    if (!element.ocs || props.ocs) {
                        return (
                            <Box mb={1} key={key}>
                                <DraggableElement
                                    element={element}
                                    elementId={key}
                                    removeContent={props.removeContent}
                                    removeContentFromGrid={props.removeContentFromGrid}
                                >
                                    {GlobalTransIntl(element.config.title, intl)}
                                </DraggableElement>
                            </Box>
                        );
                    }

                    return null;
                })
            }
            {dialog}
        </CardContent>
    );
};

ModulesDragables.propTypes = {
    removeContent: PropTypes.func.isRequired,
    removeContentFromGrid: PropTypes.func.isRequired,
    removeTab: PropTypes.func.isRequired,
    pageIndex: PropTypes.number.isRequired,
    formButtonRef: PropTypes.any.isRequired,
    ocs: PropTypes.bool.isRequired,
}

export default ModulesDragables;