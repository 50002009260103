import React, {useState} from 'react';
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {inputOnlyIntegerAndZero} from "../../helper/Helper";
import {useIntl} from "react-intl";
import {Grid, Typography} from "@material-ui/core";

const ModuleTimePicker = (props) => {
    const intl = useIntl();

    const [minutes, setMinutes] = useState((props.value >= 60) ? Math.floor(props.value / 60) : 0);
    const [seconds, setSeconds] = useState((props.value >= 60) ? props.value % 60 : (props.value || 0));

    const updateTime = (value, type) => {
        const calculatedTotalSeconds = (type === 1) ? Number(value) * 60 + Number(seconds) : Number(minutes) * 60 + Number(value);

        if (type === 1) {
            setMinutes(value);
        } else {
            setSeconds(value);
        }

        props.setValue(calculatedTotalSeconds);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography>{GlobalTransIntl('time', intl)}</Typography>
            </Grid>
            <Grid item xs>
                <TextField label={GlobalTransIntl('minutes', intl)}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyIntegerAndZero(e, (value) => updateTime(value, 1))
                           }}
                           value={minutes}
                />
            </Grid>
            <Grid item xs>
                <TextField label={GlobalTransIntl('seconds', intl)}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyIntegerAndZero(e, (value) => updateTime(value), 59)
                           }}
                           value={seconds}
                />
            </Grid>
        </Grid>
    );
};

ModuleTimePicker.propTypes = {
    value: PropTypes.number,
    setValue: PropTypes.func.isRequired,
}

export default ModuleTimePicker;