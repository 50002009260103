import React, {useCallback, useState} from 'react';
import {
    Box, Checkbox,
    Grid,
    IconButton,
    InputLabel, List, ListItem, ListItemText,
    MenuItem,
    Select,
    Typography
} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import PropTypes from "prop-types";
import {GlobalTransIntl} from "../../../helper/GlobalTrans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import FormControl from "@material-ui/core/FormControl";
import {selectIconComponent} from "../../../helper/Helper";
import {v4 as uuidv4} from "uuid";
import TranslationsInput from "../../translations/TranslationsInput";

const ForcedChoiceElement = (props) => {
    const intl = useIntl();
    const elementName = GlobalTransIntl(props.title, intl);

    const getDefaultText = {
        id: uuidv4(),
        label: '',
        dimension: '',
        revert: false,
    };

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    // optional - when having answers
    const [options, setOptions] = useState((props.options.length) ? props.options : [getDefaultText]);
    const [orderLabels, setOrderLabels] = useState((props.orderLabels.length) ? props.orderLabels : ['']);

    const updateText = (index, content) => {
        setOptions(prev => {
            const newOptions = [...prev];
            const newOption = {...prev[index], ...content};

            newOptions.splice(index, 1);
            newOptions.splice(index, 0, newOption);

            return newOptions;
        });
    };

    const addOrderLabel = () => {
        setOrderLabels(prev => {
            const newOrderLabels = [...prev];
            newOrderLabels.push('');

            return newOrderLabels;
        });
    };

    const addText = () => {
        setOptions(prev => {
            const newOptions = [...prev];
            const defaultText = getDefaultText;

            defaultText.id = uuidv4();
            newOptions.push(defaultText);

            return newOptions;
        });

        addOrderLabel();
    };

    const removeOrderLabel = (index) => {
        setOrderLabels(prev => {
            const newOrderLabels = [...prev];

            newOrderLabels.splice(index, 1);

            return newOrderLabels;
        });

        setTranslations(prev => {
            const translations = [...prev];

            translations.forEach((translation) => {
                translation['orderLabel'].splice(index, 1);
            });

            return translations;
        });
    };

    const removeText = (index) => {
        setOptions(prev => {
            const newOptions = [...prev];

            newOptions.splice(index, 1);

            return newOptions;
        });

        setTranslations(prev => {
            const translations = [...prev];

            translations.forEach((translation) => {
                translation['options'].splice(index, 1);
            });

            return translations;
        });

        removeOrderLabel(index);
    };

    const updateOrderLabel = (index, content) => {
        setOrderLabels(prev => {
            const newOrderLabels = [...prev];

            newOrderLabels.splice(index, 1);
            newOrderLabels.splice(index, 0, content);

            return newOrderLabels;
        });
    };

    const getDimensionNameById = useCallback((id) => {
        const dimension = props.dimensions.find(dimension => dimension.id === id);

        if (dimension) {
            return ` (${dimension.name})`;
        }
    }, [props.dimensions]);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            options,
            orderLabels,
            translations,
        };

        props.updateContent(props, data);
    }, [props, options, orderLabels, translations]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <Typography>{GlobalTransIntl('order_labels', intl)}</Typography>
            </Box>
            <Box mb={2}>
                <Grid container spacing={2}>
                {
                    orderLabels.map((orderLabel, index) =>
                        <Grid item xs={12} key={index}>
                            <TranslationsInput
                                field={'orderLabels'}
                                fieldType={'array'}
                                fieldIndex={index}
                                defaultValue={orderLabel}
                                setDefaultValue={(value) => {
                                    updateOrderLabel(index, value)
                                }}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('order_label', intl) + ' ' + (index + 1)}
                                setTranslations={setTranslations}
                                attributes={{
                                    required: true,
                                    autoComplete: 'off',
                                }}
                            />
                        </Grid>
                    )
                }
                </Grid>
            </Box>
            <Box mb={2}>
                <Typography>{GlobalTransIntl('texts', intl)}</Typography>
            </Box>
            {
                options.map((text, index) =>
                    <Box mb={2} key={index}>
                        <Box mb={2}>{text.id}</Box>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <TranslationsInput
                                    field={'options'}
                                    fieldType={'array'}
                                    fieldIndex={index}
                                    defaultValue={text.label}
                                    setDefaultValue={(value) => {
                                        updateText(index, {label: value})
                                    }}
                                    translations={translations}
                                    language={translationLanguage}
                                    label={GlobalTransIntl('text', intl) + ' ' + (index + 1)}
                                    setTranslations={setTranslations}
                                    attributes={{
                                        required: true,
                                        autoComplete: 'off',
                                    }}
                                />
                            </Grid>
                            <Grid item xs>
                                <FormControl variant={'outlined'} required>
                                    <InputLabel id={"module-dimensions-select-label-" + index}>
                                        {GlobalTransIntl('dimensions', intl)}
                                    </InputLabel>
                                    <Select
                                        labelId={"module-dimensions-select-label-" + index}
                                        value={text.dimension}
                                        label={GlobalTransIntl('dimensions', intl)}
                                        onChange={(event) => updateText(index, {dimension: event.target.value})}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            props.dimensions.map((dimension, index) => (
                                                <MenuItem key={index} value={dimension.id}>
                                                    {dimension.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={1} className={'align-self--center'}>
                                <Checkbox
                                    checked={text.revert}
                                    onChange={(e) => updateText(index, {revert: e.target.checked})}
                                    inputProps={{'aria-label': GlobalTransIntl('revert_score', intl)}}
                                    title={GlobalTransIntl('revert_score', intl)}
                                />
                            </Grid>
                            {
                                options.length > 1 &&
                                <Grid item xs={1} className={'align-self--center text-align-center'}>
                                    <IconButton
                                        onClick={() => removeText(index)}
                                        title={GlobalTransIntl('delete', intl)}
                                    >
                                        <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                                    </IconButton>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                )
            }
            <Box mb={2}>
                <IconButton onClick={addText}>
                    <FontAwesomeIcon icon={faPlus} size={"xs"}/>
                </IconButton>
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translationLanguage={translationLanguage}
                setTranslationLanguage={setTranslationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.orderLabels.length > 0 &&
                <Box mb={2}>
                    <Typography>{GlobalTransIntl('order_labels', intl)}</Typography>
                    <List>
                        {
                            props.orderLabels.map((label, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={`${(index + 1)} - ${label}`}/>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }
            {
                props.options.length > 0 &&
                <Box mb={2}>
                    <Typography>{GlobalTransIntl('texts', intl)}</Typography>
                    <List>
                        {
                            props.options.map((text, index) =>
                                <ListItem key={index}>
                                    <ListItemText primary={text.label + getDimensionNameById(text.dimension)}/>
                                </ListItem>
                            )
                        }
                    </List>
                </Box>
            }
        </Box>
    );
};

ForcedChoiceElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
    dimensions: PropTypes.array.isRequired,
}

// Default config for the index.js array
export const ForcedChoiceElementConfig = {
    title: 'forced_choice',
    options: [],
    orderLabels: [],
    translations: [],
    validationType: 'sort',
    noAnswerTrigger: true,
}

export default ForcedChoiceElement;