import React, {useCallback, useEffect, useState} from 'react';
import {useStore} from "../../store/useStore";
import {useIntl} from "react-intl";
import axios from "axios";
import {helperCatchErrors, selectIconComponent} from "../../helper/Helper";
import Api from "../../helper/Api";
import {FormattedMessage} from "react-intl.macro";
import Spinner from "../spinner/Spinner";
import {Box, CardContent, Grid, InputLabel, MenuItem, Select} from "@material-ui/core";
import Notifications from "../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import MaterialButton from "@material-ui/core/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import DnDSortItem from "../../helper/DnDSortItem";
import config from "../../config/config";
import IconButton from "@material-ui/core/IconButton";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";

const DimensionSortingForm = () => {
    const {state, dispatch} = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [timeoutWatcher, setTimeoutWatcher] = React.useState(0);

    const [name, setName] = useState(editForm?.name || '');
    const [sorted, setSorted] = useState(editForm?.sorted || []);
    const [selectedConstruct, setSelectedConstruct] = useState('');
    const [activeConstruct, setActiveConstruct] = useState('');

    // Loading
    const [loadingConstructs, setLoadingConstructs] = useState(true);
    const [constructs, setConstructs] = useState([]);
    const [loadingDimensions, setLoadingDimensions] = useState(true);
    const [dimensions, setDimensions] = useState([]);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState('');

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const showSuccess = () => {
        window.scrollTo(0, 0);
        setNotificationError(false);
        setNotificationSuccess(true);
    };

    const resetForm = () => {
        setActiveConstruct('');
        setName('');
        setSorted([]);
        setSelectedConstruct('');
    }

    const showError = useCallback((errorMessage = '') => {
        setNotificationErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }, []);

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl, showError]);

    const getConstructs = useCallback(() => {
        Api.getApi(
            'constructs',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setConstructs(res.data['hydra:member']);
                }

                setLoadingConstructs(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getDimensions = useCallback(() => {
        Api.getApi(
            'dimensions',
            (res) => {
                if (res.data['hydra:member'].length) {
                    setDimensions(res.data['hydra:member']);
                }

                setLoadingDimensions(false);
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    useEffect(() => {
        return () => {
            if (timeoutWatcher) {
                clearTimeout(timeoutWatcher);
            }

            cancelToken.cancel();
        };
    }, [timeoutWatcher, cancelToken]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            getConstructs();
            getDimensions();
        }
    }, [dispatch, editForm, mounted, getConstructs, getDimensions]);

    const addConstruct = () => {
        if (!selectedConstruct) {
            return null;
        }

        const construct = constructs.find((item) => item['@id'] === selectedConstruct);

        setSorted(prev => [
            ...prev,
            {
                '@id': construct['@id'],
                dimensions: construct.dimensions
            }
        ]);

        setSelectedConstruct('');
    }

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        const data = {
            name,
            sorted
        }

        if (editForm) {
            axios.put(config.apiUrl + `/dimension_sortings/${editForm.id}`, data, config.axiosConfig(state.token, {
                cancelToken: cancelToken.token,
                headers: {'Content-Type': 'application/ld+json'}
            }))
                .then(res => {
                    if (res.data && res.data['@id']) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                            resetForm();
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/dimension_sortings`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data && res.data['@id']) {
                        showSuccess();

                        setTimeoutWatcher(setTimeout(() => {
                            resetNotifications();
                            resetForm();
                        }, 3000));
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='dimension_sorting.edit.headline' defaultMessage='Edit dimensions sorting'/>;
        }

        return <FormattedMessage id='dimension_sorting.create.headline' defaultMessage='Create dimension sorting'/>;
    };

    const SelectConstruct = useCallback(() => {
        const filteredConstructs = constructs.filter(
            construct => !Object.keys(sorted).find((key) => sorted[key]['@id'] === construct['@id'])
        );

        return (
            <Select
                labelId="dimension-sorting--select-construct"
                id="dimension-sorting-select-construct"
                value={selectedConstruct}
                label={GlobalTransIntl('construct', intl)}
                onChange={(event) => setSelectedConstruct(event.target.value)}
                IconComponent={selectIconComponent}
                fullWidth
            >
                {
                    filteredConstructs.map((construct, index) => (
                        <MenuItem key={index} value={construct['@id']}>
                            {construct.name}
                        </MenuItem>
                    ))
                }
            </Select>
        );
    }, [constructs, selectedConstruct, intl, setSelectedConstruct, sorted]);

    const findConstructItem = useCallback((id) => {
        const item = sorted.find((item) => item['@id'] === id);

        return {
            item,
            index: sorted.indexOf(item),
        };
    }, [sorted]);

    const findDimensionItem = useCallback((id) => {
        const construct = findConstructItem(activeConstruct);
        const item = construct.item.dimensions.find((item) => item === id);

        return {
            item,
            index: construct.item.dimensions.indexOf(item),
        };
    }, [activeConstruct, findConstructItem]);

    const moveConstructItem = useCallback((id, atIndex) => {
        const {item, index} = findConstructItem(id);

        setSorted(prev => {
            const newItems = [...prev];

            newItems.splice(index, 1);
            newItems.splice(atIndex, 0, item);

            return newItems;
        });
    }, [findConstructItem]);

    const moveDimensionItem = useCallback((id, atIndex) => {
        const {item, index} = findDimensionItem(id);

        setSorted(prev => {
            const newItems = [...prev];
            const construct = findConstructItem(activeConstruct);

            newItems[construct.index].dimensions.splice(index, 1);
            newItems[construct.index].dimensions.splice(atIndex, 0, item);

            return newItems;
        });
    }, [findConstructItem, activeConstruct, findDimensionItem]);

    const removeConstruct = useCallback((id) => {
        const construct = findConstructItem(id);

        if (activeConstruct === id) {
            setActiveConstruct('');
        }

        setSorted(prev => {
            const newContent = [...prev];

            newContent.splice(construct.index, 1);

            return newContent;
        });
    }, [findConstructItem, activeConstruct]);

    const ConstructList = (
        !loadingConstructs && !loadingDimensions && !!sorted.length &&
        (
            sorted.map((item) => {
                const construct = constructs.find(construct => construct['@id'] === item['@id']);

                return (
                    <DnDSortItem
                        key={construct.id}
                        itemId={construct['@id']}
                        findItem={findConstructItem}
                        moveItem={moveConstructItem}
                        itemDragType={config.dimensionSortingConstructDragItem}
                        onClick={(e) => {
                            if (e.target.tagName === 'DIV') {
                                setActiveConstruct(construct['@id']);
                            }
                        }}
                    >
                        <Grid container spacing={2} className={'align-items-center'}>
                            <Grid item>{construct.name}</Grid>
                            <Grid item>
                                <IconButton aria-label={GlobalTransIntl('remove', intl)}
                                            title={GlobalTransIntl('remove', intl)}
                                            size="small"
                                            onClick={() => removeConstruct(construct['@id'])}
                                >
                                    <FontAwesomeIcon icon={faTrashAlt}/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </DnDSortItem>
                );
            })
        )
    );

    const getActiveConstruct = () => {
        return sorted.find(item => item['@id'] === activeConstruct);
    }

    const DimensionList = (
        !!getActiveConstruct()?.dimensions.length &&
        (
            getActiveConstruct().dimensions.map((item) => {
                const dimension = dimensions.find((dimension) => dimension['@id'] === item);

                return (
                    <DnDSortItem
                        key={dimension['@id']}
                        itemId={dimension['@id']}
                        findItem={findDimensionItem}
                        moveItem={moveDimensionItem}
                        itemDragType={config.dimensionSortingDimensionDragItem}
                    >
                        <Grid container spacing={2} className={'align-items-center cursor-pointer'}>
                            <Grid item>{dimension.name}</Grid>
                        </Grid>
                    </DnDSortItem>
                );
            })
        )
    );

    return (
        <Paper>
            <Spinner show={loadingConstructs || loadingDimensions} rowClass={'p-5'}/>
            <Box pt={2} pr={2} pl={2}>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={notificationErrorMessage}
                />
            </Box>
            {
                !loadingConstructs && !loadingDimensions &&
                <CardContent>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h1>
                                    <Headline/>
                                </h1>
                            </Grid>
                        </Grid>
                    </Box>
                    <form onSubmit={sendSubmit}>
                        <Box mb={2}>
                            <TextField label={GlobalTransIntl('name', intl)}
                                       id={`dimension-sorting--name`}
                                       autoComplete={'off'}
                                       variant="outlined"
                                       onChange={(e) => setName(e.target.value)}
                                       value={name}
                                       required
                            />
                        </Box>
                        <Box mb={2}>
                            <FormControl variant={'outlined'}>
                                <Grid container className={'align-items-center'} spacing={2}>
                                    <Grid item xs>
                                        <InputLabel id="dimension-sorting-create--select-construct">
                                            {GlobalTransIntl('construct', intl)}
                                        </InputLabel>
                                        <SelectConstruct/>
                                    </Grid>
                                    <Grid item>
                                        <Box maxWidth={200}>
                                            <MaterialButton
                                                variant="contained"
                                                color="default"
                                                startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                                                onClick={addConstruct}
                                                aria-label={GlobalTransIntl('add_construct', intl)}
                                                title={GlobalTransIntl('add_construct', intl)}
                                            >
                                                {GlobalTransIntl('add_construct', intl)}
                                            </MaterialButton>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Box>
                        <Grid container>
                            <Grid item xs>
                                <h2>{GlobalTransIntl('constructs', intl)}</h2>
                                {ConstructList}
                            </Grid>
                            <Grid item xs>
                                <h2>{GlobalTransIntl('dimensions', intl)}</h2>
                                {DimensionList}
                            </Grid>
                        </Grid>
                        <Box mt={2}>
                            <Button variant="primary" type="submit" className='dimension-sorting--submit'>
                                {
                                    (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                                }
                            </Button>
                        </Box>
                    </form>
                </CardContent>
            }
        </Paper>
    );
};

export default DimensionSortingForm;