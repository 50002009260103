import React from 'react';
import {useStore} from "../../store/useStore";
import GlobalTrans from "../../helper/GlobalTrans";
import pages from "../../config/pages";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import Paper from "@material-ui/core/Paper";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {getTranslationFromTranslations} from "../../helper/Helper";
import ApiFilterBuilder from "../../helper/ApiFilterBuilder";

const DataProtectionsOverview = () => {
    const { state, dispatch } = useStore();

    const columns = [
        {
            field: 'project', headerName: GlobalTrans('project'), width: 350,
            valueGetter: (params) => getTranslationFromTranslations(params.value.translations, state.localeIRI, 'name', params.value.name),
        },
        {
            field: 'procedureObject', headerName: GlobalTrans('assessment'), width: 350,
            valueGetter: (params) => getTranslationFromTranslations(params.value.translations, state.localeIRI, 'name', params.value.name),
        },
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.DATA_PROTECTION_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={()=> onEdit(props)} aria-label={GlobalTrans('title_edit')} title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    const filter = ApiFilterBuilder([
        {
            name: 'isNewestVersion',
            value: 1,
        }
    ]);

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'data_protections'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'select',
                        getUrl: 'projects',
                        name: 'project',
                        label: GlobalTrans('project'),
                        filterOperator: 'equals'
                    },
                    {
                        type: 'select',
                        getUrl: 'procedures' + filter,
                        name: 'procedureObject',
                        label: GlobalTrans('assessment'),
                        filterOperator: 'equals'
                    },
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
};

export default DataProtectionsOverview;