import React, {useEffect, useRef, useState} from "react";
import Form from "react-bootstrap/cjs/Form";
import {FormattedMessage} from "react-intl.macro";
import Paper from "@material-ui/core/Paper";
import {CardContent} from "@material-ui/core";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {FormattedMessage as FormattedMessageReactIntl, useIntl} from "react-intl";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/cjs/Button";
import Notifications from "../notifications/Notifications";
import axios from "axios";
import config from "../../config/config";
import {useStore} from "../../store/useStore";
import Spinner from "../spinner/Spinner";
import messages from "../../config/messages";
import {
    hasErrorResponseDescription,
    hasErrorResponseViolation,
    selectIconComponent,
    translateViolations
} from "../../helper/Helper";

function ThemeForm() {
    const { state, dispatch } = useStore();
    const intl = useIntl();
    const editForm = Object.keys(state.editForm).length > 0;
    const editFormId = state.editForm.id || null;
    const uploadRef = useRef();

    // states
    const [customer, setCustomer] = useState(state.editForm.customer || '');
    const [name, setName] = useState(state.editForm.name || '');
    const [backgroundColor, setBackgroundColor] = useState(state.editForm.backgroundColor || '#000000');
    const [fontColor, setFontColor] = useState(state.editForm.fontColor || '#000000');
    const [fontFamily, setFontFamily] = useState(state.editForm.fontFamily || '');
    const [upload, setUpload] = useState('');
    const [uploadFiles, setUploadFiles] = useState('');
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [customers, setCustomers] = useState(false);
    const [loadingData, setLoadingData] = useState(true);

    // Notification states
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [notificationErrorMessage, setNotificationErrorMessage] = useState(GlobalTrans('notification_error'));

    let uploadIRI = '';

    useEffect(() => {
        if (!mounted) {
            if (editForm) {
                dispatch({type: "resetEditForm"});
            }

            axios.get(config.apiUrl + `/customers`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(res => {
                    if (res.data) {
                        setCustomers(res.data['hydra:member']);
                        setLoadingData(false);
                    }
                })
                .catch(error => {
                    if (error.response) {
                        setNotificationError(true);
                    }
                });
            setMounted(true);
        }
    }, [mounted, editForm, dispatch, state.token, cancelToken]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken]);

    const resetNotifications = () => {
        setNotificationSuccess(false);
        setNotificationError(false);
    };

    const catchErrors = (error) => {
        if (uploadIRI) {
            deleteUpload();
        }

        if (hasErrorResponseViolation(error)) {
            showError(translateViolations(error.response.data['violations'], intl));
        } else if(hasErrorResponseDescription(error)) {
            showError(error.response.data['hydra:description']);
        } else if(error.response) {
            showError();
        }
    }

    const showError = (errorMessage = '') => {
        if (errorMessage.length) {
            setNotificationErrorMessage(errorMessage);
        } else {
            setNotificationErrorMessage(GlobalTrans('notification_error'));
        }
        setNotificationError(true);
    }

    const sendLogo = () => {
        let formData = new FormData();

        formData.append('file', uploadFiles[0]);

        // Files will be send
        axios.post(config.apiUrl + `/media_objects`, formData, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'multipart/form-data'}
        }))
            .then(res => {
                if (res.data) {
                    uploadIRI = res.data['@id'];
                    sendTheme();
                } else {
                    showError();
                }
            })
            .catch(catchErrors);
    }

    const sendThemeThen = (res) => {
        window.scrollTo(0, 0);

        if (res.data) {
            setName('');
            setNotificationSuccess(true);
        } else {
            deleteUpload();
            showError();
        }
    }

    const sendTheme = () => {
        let data = {
            name,
            customer,
            backgroundColor,
            fontColor,
            fontFamily
        };

        if (uploadIRI) {
            data.logo = uploadIRI
        }

        if (editForm && editFormId) {
            axios.put(config.apiUrl + `/themes/${editFormId}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(sendThemeThen)
                .catch(catchErrors);
        } else {
            axios.post(config.apiUrl + `/themes`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then(sendThemeThen)
                .catch(catchErrors);
        }
    }

    const sendSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        if (uploadFiles) {
            sendLogo();
        } else {
            sendTheme();
        }
    }

    const deleteUpload = () => {
        axios.delete(config.apiHost + uploadIRI, config.axiosConfig(state.token, {cancelToken: cancelToken.token})).then(() => uploadIRI = '');
    }

    const UploadButtonLabel = () => {
        return upload || <FormattedMessage id='theme.create.upload.button' defaultMessage='Upload'/>;
    }

    const handleUploadChange = () => {
        if (uploadRef.current?.files.length) {
            setUploadFiles(uploadRef.current.files);
            setUpload(uploadRef.current.files[0].name);
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='theme.edit.headline' defaultMessage='Edit theme' />;
        }

        return <FormattedMessage id='theme.create.headline' defaultMessage='Create theme' />;
    }

    const getCardContent = () => {
        if (loadingData) {
            return null;
        }

        return (
            <CardContent>
                <Form className={'theme--create--form'} onSubmit={sendSubmit}>
                    <Row>
                        <Col cs={12} md={6}>
                            <Form.Group controlId="formBasicName">
                                <FormattedMessageReactIntl {...messages.placeholder_name}>
                                    {
                                        placeholder =>
                                            <TextField label={placeholder}
                                                       id={`theme--create--name`}
                                                       autoComplete={'off'}
                                                       variant="outlined"
                                                       onChange={(e) => {setName(e.target.value)}}
                                                       value={name}
                                                       required
                                            />
                                    }
                                </FormattedMessageReactIntl>
                            </Form.Group>
                        </Col>
                        <Col cs={12} md={6}>
                            <Form.Group controlId="formBasicCustomer">
                                <FormControl variant={'outlined'} className={'theme--create--form-control'} id={'theme-create-customer'} required>
                                    <InputLabel>
                                        {GlobalTransIntl('customer', intl)}
                                    </InputLabel>
                                    <Select
                                        id="theme--create--customer-select"
                                        value={customer}
                                        onChange={(e) => {setCustomer(e.target.value)}}
                                        label={GlobalTransIntl('customer', intl)}
                                        IconComponent={selectIconComponent}
                                    >
                                        {
                                            customers.map((item, key) =>
                                                <MenuItem value={item['@id']} key={key} data-name={item.name}>{item.name}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col cs={12} md={6}>
                            <Form.Group controlId="formBasicFontFamily">
                                <FormattedMessageReactIntl {...messages.font_family}>
                                    {
                                        placeholder =>
                                            <TextField label={placeholder}
                                                       id={'theme--create-font-family'}
                                                       variant="outlined"
                                                       onChange={(e) =>{setFontFamily(e.target.value)}}
                                                       value={fontFamily}
                                            />
                                    }
                                </FormattedMessageReactIntl>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col cs={6} md={3}>
                            <Form.Group>
                                <Form.Label htmlFor="backgroundColor">
                                    <FormattedMessage id='theme.create.background_color.label' defaultMessage='Background color' />
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="backgroundColor"
                                    title="Choose your color"
                                    className={'colorpicker'}
                                    onChange={(e) => {setBackgroundColor(e.target.value)}}
                                    value={backgroundColor}
                                />
                            </Form.Group>
                        </Col>
                        <Col cs={6} md={3}>
                            <Form.Group>
                                <Form.Label htmlFor="fontColor">
                                    <FormattedMessage id='theme.create.font_color.label' defaultMessage='Font color' />
                                </Form.Label>
                                <Form.Control
                                    type="color"
                                    id="fontColor"
                                    title="Choose your color"
                                    className={'colorpicker'}
                                    onChange={(e) => {setFontColor(e.target.value)}}
                                    value={fontColor}
                                />
                            </Form.Group>
                        </Col>
                        <Col cs={12} md={6} className={'align-self-end'}>
                            <Form.Group controlId="formBasicUpload">
                                <input
                                    ref={uploadRef}
                                    onChange={handleUploadChange}
                                    className="d-none"
                                    accept="image/*"
                                    type="file"
                                />
                                <Button variant={(upload) ? 'success' : 'secondary'} onClick={() => {uploadRef.current?.click()}}>
                                    <UploadButtonLabel/>
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" type="submit" className='project-create--submit'>
                        {GlobalTransIntl('button_create', intl)}
                    </Button>
                </Form>
            </CardContent>
        );
    }

    return (
        <React.Fragment>
            <h1>
                <Headline/>
            </h1>
            <Notifications success={notificationSuccess}
                           error={notificationError}
                           errorMessage={notificationErrorMessage}
            />
            <Row>
                <Col cs={12} lg={6}>
                    <Paper className={'theme--create-paper'}>
                        <Spinner show={loadingData} rowClass={'p-5'} />
                        {
                            getCardContent()
                        }
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ThemeForm;