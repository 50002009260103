import React, {useCallback, useEffect, useState} from 'react';
import Form from "react-bootstrap/cjs/Form";
import TextField from "@material-ui/core/TextField";
import GlobalTrans from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import Notifications from "../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import {FormattedMessage} from "react-intl.macro";
import {useStore} from "../../store/useStore";
import Validation from "../../helper/Validation";
import axios from "axios";
import config from "../../config/config";
import {getTokenId} from "../../helper/Helper";
import pages from "../../config/pages";
import {CardContent} from "@material-ui/core";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";

const ChangePassword = () => {
    const { state, dispatch } = useStore();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [password, setPassword] = useState('');
    const [validPassword, setValidPassword] = useState(true);
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const [validPasswordRepeat, setValidPasswordRepeat] = useState(true);

    // Notifications
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    const isPasswordValid = useCallback(() => {
        let isPasswordValid = Validation.validatePassword(password);
        setValidPassword(isPasswordValid);

        return isPasswordValid;
    }, [password]);

    const isPasswordRepeatValid = useCallback(() => {
        let isPasswordRepeatValid = Validation.validatePasswordRepeat(password, passwordRepeat);
        setValidPasswordRepeat(isPasswordRepeatValid);

        return isPasswordRepeatValid;
    }, [password, passwordRepeat]);

    const resetNotification = () => {
        setNotificationError(false);
    }

    const showError = (message = '') => {
        setErrorMessage(message);
        setNotificationError(true);
    }

    const onSubmit = useCallback((event) => {
        let validatePassword = isPasswordValid(),
            validatePasswordRepeat = isPasswordRepeatValid();

        event.preventDefault();
        resetNotification();

        if (validatePassword && validatePasswordRepeat) {
            let data = {
                    password
                },
                tokenId = getTokenId(state.token);

            axios.patch(config.apiUrl + `/users/${tokenId}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'content-type': 'application/merge-patch+json'}}))
                .then(res => {
                    if (res.status === 200) {
                        dispatch({type: "setPage", payload: pages.DASHBOARD});
                    } else {
                        console.log(res);
                        showError();
                    }
                })
                .catch(() => {
                    showError();
                });
        }
    }, [dispatch, isPasswordValid, isPasswordRepeatValid, password, state.token, cancelToken.token]);

    return (
        <React.Fragment>
            <h1>
                <FormattedMessage id='account.change_password.headline' defaultMessage='Change password' />
            </h1>
            <Notifications
                error={notificationError}
                errorMessage={errorMessage}
            />
            <Row>
                <Col cs={12} lg={6}>
                    <Paper>
                        <CardContent>
                            <Form className={'change-password--form'} onSubmit={onSubmit}>
                                <Form.Group controlId="formBasicPassword">
                                    <TextField error={!validPassword}
                                               label={GlobalTrans('password')}
                                               id={'forgot-password-password'}
                                               variant="outlined"
                                               onChange={(e) => setPassword(e.target.value)}
                                               helperText={GlobalTrans('error_password')}
                                               type={'password'}
                                               value={password}
                                               required
                                    />
                                </Form.Group>
                                <Form.Group controlId="formBasicPasswordRepeat">
                                    <TextField error={!validPasswordRepeat}
                                               label={GlobalTrans('password_repeat')}
                                               id={'forgot-password-password-repeat'}
                                               variant="outlined"
                                               onChange={(e) => setPasswordRepeat(e.target.value)}
                                               helperText={GlobalTrans('error_password_repeat')}
                                               type={'password'}
                                               value={passwordRepeat}
                                               required
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit" block className='change-password--submit'>
                                    {
                                        GlobalTrans('button_submit')
                                    }
                                </Button>
                            </Form>
                        </CardContent>
                    </Paper>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default ChangePassword;