import React from 'react';
import PropTypes from "prop-types";
import {Box} from "@material-ui/core";
import {useDrop} from "react-dnd";
import config from "../../../../config/config";

const GridElementCol = (props) => {
    const [, drop] = useDrop(() => ({
        accept: config.moduleDragItem,
        hover: (draggedElement) => {
            if (
                draggedElement.elementType === 'grid'
                ||
                draggedElement.elementType === 'mailTasks'
                || (
                    (draggedElement.grid && draggedElement.grid === props.gridId)
                    &&
                    (draggedElement.gridCol !== undefined && draggedElement.gridCol === props.index)
                )) {
                return false;
            }

            props.moveElementToGrid(props.index, draggedElement);
        }
    }), [props]);

    return (
        <Box className={'theme-border'} minHeight={50} height={'100%'} ref={drop}>
            {props.children}
        </Box>
    );
};

GridElementCol.propTypes = {
    gridId: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    moveElementToGrid: PropTypes.func.isRequired,
}

export default GridElementCol;