import React, {useCallback, useEffect, useState} from 'react';
import {FormattedMessage} from "react-intl.macro";
import Notifications from "../notifications/Notifications";
import Paper from "@material-ui/core/Paper";
import {Box, CardContent, FormLabel, Grid, Tooltip} from "@material-ui/core";
import Form from "react-bootstrap/cjs/Form";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import {useIntl} from "react-intl";
import GlobalTrans, {GlobalTransIntl} from "../../helper/GlobalTrans";
import Button from "react-bootstrap/cjs/Button";
import {useStore} from "../../store/useStore";
import axios from "axios";
import {getTranslationFromTranslations, helperCatchErrors, selectIconComponent} from "../../helper/Helper";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import MaterialButton from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import config from "../../config/config";
import Spinner from "../spinner/Spinner";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import DnDSort from "../../helper/DnDSort";
import DnDSortItem from "../../helper/DnDSortItem";
import pages from "../../config/pages";
import TranslationLanguages from "../translationLanguages/TranslationLanguages";
import TranslationsInput from "../translations/TranslationsInput";
import Api from "../../helper/Api";
import CustomDialog from "../../helper/CustomDialog";

const AssessmentsForm = () => {
    const { state, dispatch } = useStore();
    const intl = useIntl();

    // states
    const [cancelToken] = useState(axios.CancelToken.source());
    const [mounted, setMounted] = useState(false);
    const [editForm] = useState((Object.keys(state.editForm).length > 0) ? state.editForm : null);
    const [hasPins] = React.useState(editForm?.hasPins || false);
    const [sendingModules, setSendingModules] = React.useState(false);
    const [sendModulesCounter, setSendModulesCounter] = React.useState(0);
    const [dialogStatus, setDialogStatus] = React.useState(false);

    // Form states
    const [name, setName] = useState(state.editForm.name || '');
    const [reportSubtitle, setReportSubtitle] = useState(state.editForm.reportSubtitle || '');
    const [reportObjective, setReportObjective] = useState(state.editForm.resultPdfObjective || '');
    const [reportObjectiveHeadline, setReportObjectiveHeadline] = useState(state.editForm.resultPdfObjectiveHeadline || '');
    const [reportEvaluation, setReportEvaluation] = useState(state.editForm.resultPdfEvaluation || '');
    const [reportEvaluationHeadline, setReportEvaluationHeadline] = useState(state.editForm.resultPdfEvaluationHeadline || '');
    const [reportTitle, setReportTitle] = useState(state.editForm.titleReport || '');
    const [frontpageHeadline, setFrontpageHeadline] = useState(state.editForm.frontpageHeadline || '');
    const [frontpageText, setFrontpageText] = useState(state.editForm.frontpageText || '');
    const [oldVersion] = useState(state.editForm.id || '');
    const [noNewVersion, setNoNewVersion] = useState(false);

    const [procedureObject, setProcedureObject] = useState('');

    const [loadingData, setLoadingData] = useState(true);
    const [loadingModules, setLoadingModules] = useState(true);
    const [loadingPdfTemplates, setLoadingPdfTemplates] = useState(true);
    const [loadingScales, setLoadingScales] = useState(true);
    const [loadingDimensionSortings, setLoadingDimensionSortings] = useState(true);
    const [loadingProcedureModules, setLoadingProcedureModules] = useState(true);
    const [modules, setModules] = useState([]);
    const [module, setModule] = useState('');
    const [pdfTemplates, setPdfTemplates] = useState([]);
    const [pdfTemplate, setPdfTemplate] = useState('');
    const [procedureModules, setProcedureModules] = useState([]);

    const [scales, setScales] = useState([]);
    const [selectedScala, setSelectedScala] = useState((state.editForm.reportScala) ? state.editForm.reportScala['@id'] : '');
    const [dimensionSortings, setDimensionSortings] = useState([]);
    const [selectedDimensionSorting, setSelectedDimensionSorting] = useState((state.editForm.dimensionSorting) ? state.editForm.dimensionSorting : '');
    const [selectedTemplates, setSelectedTemplates] = useState(state.editForm.resultPdfTemplates || []);

    const [selectedModules, setSelectedModules] = useState([]);
    const [deleteOldModules, setDeleteOldModules] = useState([]);
    const [deleteOldModulesTotal, setDeleteOldModulesTotal] = useState(0);
    const [deleteOldModulesCounter, setDeleteOldModulesCounter] = useState(0);
    const [newProcedureId, setNewProcedureId] = useState('');

    const [checkboxIsStatisticOn, setCheckboxIsStatisticOn] = useState(state.editForm.statisticsOn ||  false);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(state.editForm.translations || []);

    // Notifications
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationError, setNotificationError] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    const catchErrors = useCallback((error) => {
        helperCatchErrors(showError, intl, error);
    }, [intl]);

    const showError = (errorMessage = '') => {
        setErrorMessage(errorMessage);
        window.scrollTo(0, 0);
        setNotificationError(true);
    }

    const resetNotifications = () => {
        setNotificationError(false);
        setNotificationSuccess(false);
    }

    useEffect(() => {
        if (!loadingModules && !loadingPdfTemplates && !loadingScales && !loadingDimensionSortings) {
            setLoadingData(false);
        }
    }, [loadingModules, loadingPdfTemplates, loadingScales, loadingDimensionSortings]);

    const getModules = useCallback(() => {
        axios.get(config.apiUrl + `/modules?isNewestVersion=true`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setModules(res.data['hydra:member']);
                    setLoadingModules(false);
                }
            })
            .catch(catchErrors);
    }, [state.token, catchErrors, cancelToken.token]);

    const getApiModule = useCallback((item, index) => {
        Api.getIri(
            item['@id'],
            (res) => {
                setProcedureModules(prev => {
                    const newPrev = [...prev];
                    newPrev[index] = res.data.module;

                    return newPrev;
                });

                setDeleteOldModules(prev => {
                    const newPrev = [...prev];
                    newPrev[index] = {delete: false, id: item.id, module: res.data.module};

                    return newPrev;
                });
            },
            catchErrors,
            state.token,
            cancelToken.token
        );
    }, [cancelToken.token, catchErrors, state.token]);

    const getResultPdfTemplates = useCallback(() => {
        axios.get(config.apiUrl + `/result/getpdftemplates`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setPdfTemplates(res.data);
                    setLoadingPdfTemplates(false);
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, state.token]);

    const getScales = useCallback(() => {
        axios.get(config.apiUrl + `/scales`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setScales(res.data['hydra:member']);
                    setLoadingScales(false);
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, state.token]);

    const getDimensionSortings = useCallback(() => {
        axios.get(config.apiUrl + `/dimension_sortings`, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
            .then(res => {
                if (res.data) {
                    setDimensionSortings(res.data['hydra:member']);
                    setLoadingDimensionSortings(false);
                }
            })
            .catch(catchErrors);
    }, [cancelToken.token, catchErrors, state.token]);

    const submitModulesForProcedure = useCallback((procedureObject = newProcedureId) => {
        setSendingModules(true);

        selectedModules.forEach((module, index) => {
            let data = {
                    module: module['@id'],
                    procedureObject,
                    moduleOrder: index
                },
                doesModuleAlreadyExist = deleteOldModules.find((item) => item.module.id === module.id) || false;

            if (deleteOldModules.length === 0 || (!doesModuleAlreadyExist || (doesModuleAlreadyExist && (doesModuleAlreadyExist.delete === true || (hasPins && !noNewVersion))))) {
                axios.post(config.apiUrl + `/modules_procedures`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then((res) => {
                        if (res.data) {
                            setSendModulesCounter((prev) => prev + 1);
                        } else {
                            showError();
                        }
                    })
                    .catch(catchErrors);
            } else if (doesModuleAlreadyExist) {
                axios.put(config.apiUrl + `/modules_procedures/${doesModuleAlreadyExist.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then((res) => {
                        if (res.data) {
                            setSendModulesCounter((prev) => prev + 1);
                        } else {
                            showError();
                        }
                    })
                    .catch(catchErrors);
            } else {
                setSendModulesCounter((prev) => prev + 1);
            }
        });
    }, [selectedModules, cancelToken.token, catchErrors, state.token, deleteOldModules, newProcedureId, hasPins, noNewVersion]);

    const deleteOldModulesApi = useCallback((procedureId) => {
        let countToDeleteModules = 0;

        deleteOldModules.forEach((item) => {
            if (item.delete === true) {
                let url = config.apiUrl + '/modules_procedures/' + item.id;

                axios.delete(url, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                    .then(() => setDeleteOldModulesCounter((prevState) => prevState + 1))
                    .catch(catchErrors);

                countToDeleteModules++;
            }
        });

        if (countToDeleteModules > 0) {
            setDeleteOldModulesTotal(countToDeleteModules);
        } else {
            submitModulesForProcedure(procedureId);
        }
    }, [deleteOldModules, cancelToken.token, catchErrors, submitModulesForProcedure, state.token]);

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        };
    }, [cancelToken]);

    useEffect(() => {
        if (editForm?.modules.length && (procedureModules.filter(item => item !== undefined)).length === editForm.modules.length) {
            setLoadingProcedureModules(false);

            setSelectedModules(procedureModules);
        }
    }, [procedureModules, editForm]);

    useEffect(() => {
        if (!mounted) {
            setMounted(true);

            if (editForm?.modules.length) {
                editForm.modules.forEach((item) => {
                    getApiModule(item, item.moduleOrder);
                });

                dispatch({type: "resetEditForm"});
            } else {
                setLoadingProcedureModules(false);
            }

            getModules();
            getResultPdfTemplates();
            getScales();
            getDimensionSortings();
        }
    }, [mounted, editForm, dispatch, getModules, getResultPdfTemplates, getScales, getDimensionSortings, getApiModule]);

    useEffect(() => {
        if (sendingModules && (selectedModules.length === sendModulesCounter)) {
            dispatch({type: "setEditForm", payload: procedureObject});
            dispatch({type: "setPage", payload: pages.ASSESSMENT_NORMGROUPS});
        }
    }, [sendingModules, selectedModules, sendModulesCounter, dispatch, procedureObject]);

    useEffect(() => {
        if (deleteOldModulesTotal > 0 && (deleteOldModulesTotal === deleteOldModulesCounter)) {
            setDeleteOldModulesTotal(0);
            setDeleteOldModulesCounter(0);

            submitModulesForProcedure();
        }
    }, [deleteOldModulesTotal, deleteOldModulesCounter, submitModulesForProcedure]);

    const submitProcedureForm = () => {
        let data = {
            name,
            frontpageHeadline,
            translations,
            reportSubtitle,
            statisticsOn: checkboxIsStatisticOn,
            dimensionSorting: selectedDimensionSorting || null,
            reportScala: selectedScala || null
        };

        if (reportTitle) {
            data.titleReport = reportTitle;
        }

        if (reportObjectiveHeadline) {
            data.resultPdfObjectiveHeadline = reportObjectiveHeadline;
        }

        if (reportObjective) {
            data.resultPdfObjective = reportObjective;
        }

        if (reportEvaluationHeadline) {
            data.resultPdfEvaluationHeadline = reportEvaluationHeadline;
        }

        if (reportEvaluation) {
            data.resultPdfEvaluation = reportEvaluation;
        }

        if (frontpageText) {
            data.frontpageText = frontpageText;
        }

        if (selectedTemplates) {
            data.resultPdfTemplates = selectedTemplates;
        }

        if (editForm && (!hasPins || noNewVersion)) {
            axios.put(config.apiUrl + `/procedures/${editForm.id}`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token, headers: {'Content-Type': 'application/ld+json'}}))
                .then(res => {
                    if (res.data && res.data['@id']) {
                        setProcedureObject(res.data);
                        setNewProcedureId(res.data['@id']);
                        deleteOldModulesApi(res.data['@id']);
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        } else {
            if (oldVersion) {
                data.oldVersion = oldVersion;
            }

            axios.post(config.apiUrl + `/procedures`, data, config.axiosConfig(state.token, {cancelToken: cancelToken.token}))
                .then((res) => {
                    if (res.data && res.data['@id']) {
                        setProcedureObject(res.data);
                        submitModulesForProcedure(res.data['@id']);
                    } else {
                        showError();
                    }
                })
                .catch(catchErrors);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        resetNotifications();

        if (editForm?.hasPins && !noNewVersion) {
            setDialogStatus(true);
        } else {
            submitProcedureForm();
        }
    }

    const Headline = () => {
        if (editForm) {
            return <FormattedMessage id='assessment.edit.headline' defaultMessage='Edit assessment' />
        }

        return <FormattedMessage id='assessment.create.headline' defaultMessage='Create assessment' />
    }

    const selectModule = useCallback(() => {
        if (!module) {
            return null;
        }

        setSelectedModules((prev) => [
            ...prev,
            modules.find((item) => item.id === module)
        ])

        setModule('');
    }, [module, modules]);

    const selectTemplate = useCallback(() => {
        if (!pdfTemplate) {
            return null;
        }

        setSelectedTemplates((prev) => [
            ...prev,
            pdfTemplate
        ])

        setPdfTemplate('');
    }, [pdfTemplate]);

    const removeSelectedModule = useCallback((id) => {
        if (deleteOldModules.length) {
            let findDeletingModule = deleteOldModules.find((item) => item.module.id === id);

            if (findDeletingModule) {
                findDeletingModule.delete = true;

                setDeleteOldModules((prev) => [
                    ...prev.filter((item) => item.module.id !== id),
                    findDeletingModule
                ])
            }
        }

        setSelectedModules((prev) => prev.filter((item) => item.id !== id));
    }, [deleteOldModules]);

    const removeSelectedTemplate = useCallback((template) => {
        const newSelectedTemplates = selectedTemplates.filter((item) => item !== template);

        setSelectedTemplates(newSelectedTemplates);
    }, [selectedTemplates]);

    const SelectModules = useCallback(() => {
        const filteredModules = modules.filter(
            module => !selectedModules.find((selectedModule) => selectedModule.id === module.id)
        );

        return (
            <Select
                labelId="assessments--module-select"
                value={module}
                onChange={(event) => {setModule(event.target.value);}}
                label={GlobalTransIntl('modules', intl)}
                IconComponent={selectIconComponent}
            >
                {
                    (
                        (filteredModules.length > 0) &&
                        filteredModules.map((item) =>
                            <MenuItem
                                value={item.id}
                                key={item.id}
                                data-name={item.name}>
                                {item.name} ({GlobalTransIntl('version_shortcut', intl)}{item.version})
                            </MenuItem>
                        )
                    )|| (
                        <MenuItem>-</MenuItem>
                    )
                }
            </Select>
        );
    }, [intl, module, selectedModules, modules]);

    const SelectTemplates = useCallback(() => {
        const filteredPdfTemplates = pdfTemplates.filter(
            pdfTemplate => !selectedTemplates.find((selectedTemplate) => selectedTemplate === pdfTemplate)
        );

        return (
            <Select
                labelId="assessments--pdf-templates-select"
                value={pdfTemplate}
                onChange={(event) => {
                    setPdfTemplate(event.target.value);
                }}
                label={GlobalTransIntl('pdf_templates', intl)}
                IconComponent={selectIconComponent}
            >
                {
                    (
                        (filteredPdfTemplates.length > 0) &&
                        filteredPdfTemplates
                            .map((template, index) =>
                                <MenuItem value={template} key={index}>{template}</MenuItem>
                            )
                    ) || (
                        <MenuItem>-</MenuItem>
                    )
                }
            </Select>
        );
    }, [intl, pdfTemplate, pdfTemplates, selectedTemplates]);

    const findModuleItem = useCallback((id) => {
        const item = selectedModules.filter((c) => c.id === id)[0];

        return {
            item,
            index: selectedModules.indexOf(item),
        };
    }, [selectedModules]);

    const moveModuleItem = useCallback((id, atIndex) => {
        const {item, index} = findModuleItem(id);

        setSelectedModules(prev => {
            const newItems = [...prev];

            newItems.splice(index, 1);
            newItems.splice(atIndex, 0, item);

            return newItems;
        });
    }, [findModuleItem]);

    const findTemplateItem = useCallback((template) => {
        const item = selectedTemplates.find(item => item === template);

        return {
            item,
            index: selectedTemplates.indexOf(item)
        };
    }, [selectedTemplates]);

    const moveTemplateItem = useCallback((template, atIndex) => {
        const {item, index} = findTemplateItem(template);

        setSelectedTemplates(prev => {
            const newItems = [...prev];

            newItems.splice(index, 1);
            newItems.splice(atIndex, 0, item);

            return newItems;
        });
    }, [findTemplateItem]);

    const ModulesList = (
        selectedModules.map((item) => (
            <DnDSortItem
                key={item.id}
                itemId={item.id}
                itemDragType={config.assessmentModuleDragItem}
                findItem={findModuleItem}
                moveItem={moveModuleItem}
            >
                <Row noGutters>
                    <Col xs={'auto'} className={'align-self--center'}>
                        {item.name} ({GlobalTransIntl('version_shortcut', intl)}{item.version})
                    </Col>
                    <Col xs={'auto'} className={'align-self--center'}>
                        <Box ml={2}>
                            <IconButton aria-label={GlobalTransIntl('remove', intl)}
                                        title={GlobalTransIntl('remove', intl)}
                                        size="small"
                                        onClick={() => removeSelectedModule(item.id)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
            </DnDSortItem>
        ))
    );

    const Modules = (
        (modules.length > 0) &&
        <Col xs={12} sm={6}>
            <Row>
                <Col xs={12} xl={8}>
                    <Form.Group controlId="modules_select">
                        <FormControl variant={'outlined'} className={'assessments--form-control'} id={'assessments-modules'}>
                            <InputLabel>
                                {GlobalTransIntl('modules', intl)}
                            </InputLabel>
                            <SelectModules/>
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col xs={12} xl={4} className={'align-self--center'}>
                    <Form.Group controlId="modules_add">
                        <MaterialButton
                            variant="contained"
                            color="default"
                            className={''}
                            startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                            onClick={selectModule}
                            aria-label={GlobalTransIntl('add_module', intl)}
                            title={GlobalTransIntl('add_module', intl)}
                        >
                            {GlobalTransIntl('add_module', intl)}
                        </MaterialButton>
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <Form.Group controlId="modules_select">
                        <FormLabel>{GlobalTransIntl('selected_modules', intl)}:</FormLabel>
                        {
                            (
                                !selectedModules.length &&
                                <Row>
                                    <Col>
                                        {GlobalTransIntl('no_modules_selected', intl)}
                                    </Col>
                                </Row>
                            )
                            ||
                            <Row>
                                <Col xs={'auto'}>
                                    <DnDSort dragItem={config.assessmentModuleDragItem}>
                                        {ModulesList}
                                    </DnDSort>
                                </Col>
                            </Row>
                        }
                    </Form.Group>
                </Col>
            </Row>
        </Col>
    );

    const TemplatesList = (
        selectedTemplates.map((item) => (
            <DnDSortItem
                key={item}
                itemId={item}
                itemDragType={config.assessmentTemplateDragItem}
                findItem={findTemplateItem}
                moveItem={moveTemplateItem}
            >
                <Row noGutters>
                    <Col xs={'auto'} className={'align-self--center'}>
                        {item}
                    </Col>
                    <Col xs={'auto'} className={'align-self--center'}>
                        <Box ml={2}>
                            <IconButton aria-label={GlobalTransIntl('remove', intl)}
                                        title={GlobalTransIntl('remove', intl)}
                                        size="small"
                                        onClick={() => removeSelectedTemplate(item)}
                            >
                                <FontAwesomeIcon icon={faTrashAlt}/>
                            </IconButton>
                        </Box>
                    </Col>
                </Row>
            </DnDSortItem>
        ))
    );

    const ResultReport = (
        (pdfTemplates.length > 0) &&
        <Col xs={12} sm={6}>
            <Row>
                <Col xs={12} xl={8}>
                    <Form.Group controlId="modules_select">
                        <FormControl variant={'outlined'} className={'assessments--form-control'} id={'assessments-pdf-templates'}>
                            <InputLabel>
                                {GlobalTransIntl('pdf_templates', intl)}
                            </InputLabel>
                            <SelectTemplates/>
                        </FormControl>
                    </Form.Group>
                </Col>
                <Col xs={12} xl={4} className={'align-self--center'}>
                    <Form.Group controlId="pdf_templates_add">
                        <MaterialButton
                            variant="contained"
                            color="default"
                            className={''}
                            startIcon={<FontAwesomeIcon icon={faPlusCircle}/>}
                            onClick={selectTemplate}
                            aria-label={GlobalTransIntl('add_template', intl)}
                            title={GlobalTransIntl('add_template', intl)}
                        >
                            {GlobalTransIntl('add_template', intl)}
                        </MaterialButton>
                    </Form.Group>
                </Col>
                <Col xs={12}>
                    <FormLabel>{GlobalTransIntl('selected_templates', intl)}:</FormLabel>
                    {
                        (
                            !selectedTemplates.length &&
                            <Row>
                                <Col>
                                    {GlobalTransIntl('no_templates_selected', intl)}
                                </Col>
                            </Row>
                        )
                        ||
                        <Row>
                            <Col xs={'auto'}>
                                <DnDSort dragItem={config.assessmentTemplateDragItem}>
                                    {TemplatesList}
                                </DnDSort>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </Col>
    );

    const getCardContent = () => {
        if (loadingData || loadingProcedureModules) {
            return null;
        }

        return (
            <Form className={'project-create--form'} onSubmit={onSubmit}>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="name">
                            <TranslationsInput
                                field={'name'}
                                defaultValue={name}
                                setDefaultValue={setName}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('name', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    required: true,
                                    id: 'project-create--name'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={checkboxIsStatisticOn}
                                    onChange={(e) => {
                                        setCheckboxIsStatisticOn(e.target.checked)
                                    }}
                                    name="save_statistics"
                                    color="primary"
                                />
                            }
                            label={GlobalTransIntl('save_statistics', intl)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2>{GlobalTransIntl('report', intl)}</h2>
                    </Col>        
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportTitle">
                            <TranslationsInput
                                field={'titleReport'}
                                defaultValue={reportTitle}
                                setDefaultValue={setReportTitle}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_title', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    required: true,
                                    multiline: true,
                                    minRows: 4,
                                    id: 'project-create--report-title'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportSubtitle">
                            <TranslationsInput
                                field={'reportSubtitle'}
                                defaultValue={reportSubtitle}
                                setDefaultValue={setReportSubtitle}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_subtitle', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    id: 'project-create--report-subtitle'
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportObjectiveHeadline">
                            <TranslationsInput
                                field={'resultPdfObjectiveHeadline'}
                                defaultValue={reportObjectiveHeadline}
                                setDefaultValue={setReportObjectiveHeadline}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_objective_headline', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    id: 'project-create--report-objective-headline'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportEvaluationHeadline">
                            <TranslationsInput
                                field={'resultPdfEvaluationHeadline'}
                                defaultValue={reportEvaluationHeadline}
                                setDefaultValue={setReportEvaluationHeadline}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_evaluation_headline', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    id: 'project-create--report-evaluation-headline'
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportObjective">
                            <TranslationsInput
                                field={'resultPdfObjective'}
                                defaultValue={reportObjective}
                                setDefaultValue={setReportObjective}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_objective', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    multiline: true,
                                    minRows: 4,
                                    id: 'project-create--report-objective'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="reportEvaluation">
                            <TranslationsInput
                                field={'resultPdfEvaluation'}
                                defaultValue={reportEvaluation}
                                setDefaultValue={setReportEvaluation}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('report_evaluation', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    multiline: true,
                                    minRows: 4,
                                    id: 'project-create--report-evaluation'
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="scala">
                            <FormControl variant={'outlined'}>
                                <InputLabel>
                                    {GlobalTransIntl('scala', intl)}
                                </InputLabel>
                                <Select
                                    value={selectedScala}
                                    onChange={(event) => {setSelectedScala(event.target.value);}}
                                    label={GlobalTransIntl('scala', intl)}
                                    IconComponent={selectIconComponent}
                                >
                                    <MenuItem value={''}>-</MenuItem>
                                    {
                                        scales.map((item) =>
                                            <MenuItem value={item['@id']} key={item.id} data-name={item.name}>
                                                {getTranslationFromTranslations(item.translations, state.localeIRI, 'name', item.name)}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="dimension-sorting">
                            <FormControl variant={'outlined'}>
                                <InputLabel>
                                    {GlobalTransIntl('dimension_sorting', intl)}
                                </InputLabel>
                                <Select
                                    value={selectedDimensionSorting}
                                    onChange={(event) => {setSelectedDimensionSorting(event.target.value);}}
                                    label={GlobalTransIntl('dimension_sorting', intl)}
                                    IconComponent={selectIconComponent}
                                >
                                    <MenuItem value={''}>-</MenuItem>
                                    {
                                        dimensionSortings.map((item) =>
                                            <MenuItem value={item['@id']} key={item.id} data-name={item.name}>
                                                {item.name}
                                            </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <h2>{GlobalTransIntl('frontpage', intl)}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="frontpageHeadline">
                            <TranslationsInput
                                field={'frontpageHeadline'}
                                defaultValue={frontpageHeadline}
                                setDefaultValue={setFrontpageHeadline}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('frontpage_headline', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    required: true,
                                    id: 'project-create--frontpage-headline'
                                }}
                            />
                        </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                        <Form.Group controlId="frontpageText">
                            <TranslationsInput
                                field={'frontpageText'}
                                defaultValue={frontpageText}
                                setDefaultValue={setFrontpageText}
                                translations={translations}
                                language={translationLanguage}
                                label={GlobalTransIntl('frontpage_text', intl)}
                                setTranslations={setTranslations}
                                attributes={{
                                    multiline: true,
                                    minRows: 4,
                                    id: 'project-create--frontpage_text'
                                }}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <h2>{GlobalTransIntl('modules', intl)}</h2>
                    </Col>
                    <Col xs={6}>
                        <h2>{GlobalTransIntl('report_templates', intl)}</h2>
                    </Col>
                </Row>
                <Row>
                    {Modules}
                    {ResultReport}
                </Row>
                {
                    (editForm?.hasPins) &&
                    <Row>
                        <Col>
                            <Form.Group>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={noNewVersion}
                                            onChange={
                                                (e) => {
                                                    setNoNewVersion(e.target.checked)
                                                }
                                            }
                                            color='primary'
                                        />
                                    }
                                    label={
                                        <React.Fragment>
                                            {GlobalTransIntl('no_new_version', intl)}
                                            <Tooltip title={GlobalTransIntl('tooltip_no_new_version', intl)}>
                                                <IconButton>
                                                    <FontAwesomeIcon icon={faQuestionCircle} size={"xs"}/>
                                                </IconButton>
                                            </Tooltip>
                                        </React.Fragment>
                                    }
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                }
                <Button variant="primary" type="submit" className='assessments-form--submit'>
                    {
                        (editForm) ? GlobalTransIntl('button_save', intl) : GlobalTransIntl('button_create', intl)
                    }
                </Button>
            </Form>
        );
    }

    return (
        <Paper>
            <CardContent>
                <Box mb={2}>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <h1>
                                <Headline/>
                            </h1>
                        </Grid>
                        <Grid item>
                            <TranslationLanguages
                                selectedLanguage={translationLanguage}
                                setLanguage={setTranslationLanguage}
                            />
                        </Grid>
                    </Grid>
                </Box>
                <Notifications
                    success={notificationSuccess}
                    error={notificationError}
                    errorMessage={errorMessage}
                />
                <Spinner show={loadingData || loadingProcedureModules} rowClass={'p-5'}/>
                {
                    getCardContent()
                }
            </CardContent>
            <CustomDialog
                text={GlobalTrans('dialog_assessment_new_version')}
                agreeFunction={submitProcedureForm}
                closeFunction={() => setDialogStatus(false)}
                open={dialogStatus}/>
        </Paper>
    );
};

export default AssessmentsForm;