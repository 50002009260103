import React, {useCallback, useState} from 'react';
import {Box, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import PropTypes from "prop-types";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import ModuleElementDimension from "../../../helper/ModuleElementDimension";
import GlobalTrans from "../../../helper/GlobalTrans";
import Form from "react-bootstrap/cjs/Form";
import TranslationsInput from "../../translations/TranslationsInput";
import ModuleElementOptions from "../../../helper/ModuleElementOptions";

const DropdownElement = (props) => {
    const elementName = GlobalTrans(props.title);

    // optional - when having answers
    const [dimension, setDimension] = useState(props.dimension || '');
    const [label, setLabel] = useState(props.label);
    const [options, setOptions] = useState((props.options.length) ? props.options : ['']);

    // Translations
    const [translationLanguage, setTranslationLanguage] = useState('');
    const [translations, setTranslations] = useState(props.translations || []);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            label,
            options,
            translations,
        };

        if (!props.checkboxDimension) {
            data.dimension = dimension;
        }

        props.updateContent(props, data);
    }, [props, dimension, label, options, translations]);

    const modalBody = (
        <React.Fragment>
            {/* optional - when having answers */}
            <ModuleElementDimension
                dimension={dimension}
                setDimension={setDimension}
                showDimension={!props.checkboxDimension}
                dimensions={props.dimensions}
            />

            <Form.Group controlId="label">
                <TranslationsInput
                    field={'label'}
                    defaultValue={label}
                    setDefaultValue={setLabel}
                    translations={translations}
                    language={translationLanguage}
                    label={GlobalTrans('title')}
                    setTranslations={setTranslations}
                    attributes={{
                        required: true,
                        autoComplete: 'off',
                    }}
                />
            </Form.Group>

            <Box mb={2}>
                <Typography>{GlobalTrans('options')}</Typography>
            </Box>
            <ModuleElementOptions
                label={'option'}
                options={options}
                setOptions={setOptions}
                translations={translations}
                setTranslations={setTranslations}
                translationLanguage={translationLanguage}
                translationsField={'options'}
            />
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                setTranslationLanguage={setTranslationLanguage}
                translationLanguage={translationLanguage}
            >
                {modalBody}
            </ModuleElementActions>
            {
                props.label.length > 0 &&
                <Typography>{props.label}</Typography>
            }
            {
                props.options.length > 0 &&
                <List>
                    {
                        props.options.map((option, index) =>
                            <ListItem key={index}>
                                <ListItemText primary={option}/>
                            </ListItem>
                        )
                    }
                </List>
            }
        </Box>
    );
};

DropdownElement.propTypes = {
    updateContent: PropTypes.func.isRequired,

    // optional - when having answers
    dimensions: PropTypes.array.isRequired,
}

// Default config for the index.js array
export const DropdownElementConfig = {
    title: 'dropdown',
    label: '',
    options: [],
    translations: [],
    validationType: 'option',
}

export default DropdownElement;