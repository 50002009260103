import React from 'react';
import {useStore} from "../../store/useStore";
import GlobalTrans from "../../helper/GlobalTrans";
import pages from "../../config/pages";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {Paper} from "@material-ui/core";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";

const ForcedChoiceWeightingOverview = () => {
    const {dispatch} = useStore();

    const columns = [
        {field: 'dimension', headerName: GlobalTrans('dimension'), width: 300,
            valueGetter: (params) => (params.value) ? params.value.name : '-'},
        {field: 'normgroup', headerName: GlobalTrans('normgroup'), width: 300,
            valueGetter: (params) => (params.value) ? params.value.name : '-'},
        {field: 'questionaireWeighting', headerName: GlobalTrans('questionaire'), width: 200},
        {field: 'forcedChoiceWeighting', headerName: GlobalTrans('forced_choice'), width: 200},
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.FORCED_CHOICE_WEIGHTING_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton
                onClick={() => onEdit(props)}
                aria-label={GlobalTrans('title_edit')}
                title={GlobalTrans('title_edit')}
            >
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'forced_choice_weightings'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'select',
                        getUrl: 'dimensions',
                        name: 'dimension',
                        label: GlobalTrans('dimension'),
                        filterOperator: 'equals'
                    },
                    {
                        type: 'select',
                        getUrl: 'normgroups',
                        name: 'normgroup',
                        label: GlobalTrans('normgroup'),
                        filterOperator: 'equals'
                    },
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
};

export default ForcedChoiceWeightingOverview;