const pages = {
    DASHBOARD: 'dashboard',
    FORGOT_PASSWORD: 'forgot-password',
    PINS_CREATE: 'pins_create',
    PINS_OPEN: 'pins_open',
    PINS_INVITED: 'pins_invited',
    PINS_STARTED: 'pins_started',
    PINS_RESULTS: 'pins_results',
    PINS_RESULTS_DETAILS: 'pins_results_details',
    PROJECT_OVERVIEW: 'project_overview',
    PROJECT_CREATE: 'project_create',
    CUSTOMER_OVERVIEW: 'customer_overview',
    CUSTOMER_CREATE: 'customer_create',
    USER_OVERVIEW: 'user_overview',
    USER_CREATE: 'user_create',
    THEME_OVERVIEW: 'theme_overview',
    THEME_CREATE: 'theme_create',
    TRANSLATIONS: 'translations',
    TRANSLATION: 'translation',
    COUNTRIES_OVERVIEW: 'countries_overview',
    COUNTRIES_CREATE: 'countries_create',
    LANGUAGES_OVERVIEW: 'languages_overview',
    LANGUAGES_CREATE: 'languages_create',
    NORMGROUPS_OVERVIEW: 'normgroups_overview',
    NORMGROUPS_CREATE: 'normgroups_create',
    ROLES_OVERVIEW: 'roles_overview',
    ROLES_CREATE: 'roles_create',
    CONSTRUCTS_OVERVIEW: 'constructs_overview',
    CONSTRUCTS_CREATE: 'constructs_create',
    DIMENSIONS_OVERVIEW: 'dimensions_overview',
    DIMENSIONS_CREATE: 'dimensions_create',
    ASSESSMENT_OVERVIEW: 'assessment_overview',
    ASSESSMENT_CREATE: 'assessment_create',
    ASSESSMENT_NORMGROUPS: 'assessment_normgroups',
    ASSESSMENT_DIMENSION_TEMPLATES: 'assessment_dimension_templates',
    MODULES_CREATE: 'modules_create',
    MODULES_OVERVIEW: 'modules_overview',
    ACCOUNT_CHANGE_PASSWORD: 'account_change_password',
    SCALES_OVERVIEW: 'scales_overview',
    SCALES_CREATE: 'scales_create',
    FORCED_CHOICE_WEIGHTING_OVERVIEW: 'forced_choice_weighting_overview',
    FORCED_CHOICE_WEIGHTING_CREATE: 'forced_choice_weighting_create',
    REPORT_DIMENSION_AVERAGES_OVERVIEW: 'report_dimension_averages_overview',
    REPORT_DIMENSION_AVERAGES_CREATE: 'report_dimension_averages_create',
    THREE_SIXTY_KEYS_OVERVIEW: 'three_sixty_keys_overview',
    THREE_SIXTY_KEYS_CREATE: 'three_sixty_keys_create',
    INVITATIONS_OVERVIEW: 'invitations_overview',
    INVITATIONS_CREATE: 'invitations_create',
    DATA_PROTECTION_OVERVIEW: 'data_protection_overview',
    DATA_PROTECTION_CREATE: 'data_protection_create',
    DIMENSION_SORTING_OVERVIEW: 'dimension_sorting_overview',
    DIMENSION_SORTING_CREATE: 'dimension_sorting_create',
    EXPORT_PIN: 'export_pin',
    GLOBAL_PIN_SEARCH: 'global_pin_search'
}

export default pages;