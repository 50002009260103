import React from 'react';
import PropTypes from "prop-types";
import {useDrop} from "react-dnd";

const DnDSort = (props) => {
    const [, drop] = useDrop(() => ({accept: props.dragItem}));

    return (
        <div ref={drop} className={(props.activeBorder) ? 'theme-border' : null}>
            {props.children}
        </div>
    );
};

DnDSort.propTypes = {
    dragItem: PropTypes.string.isRequired,
    activeBorder: PropTypes.bool,
}

DnDSort.defaultProps = {
    activeBorder: false,
}

export default DnDSort;