import axios from "axios";
import config from "../config/config";

const Api = {
    getLanguages: (callbackSuccess, callbackError, token, cancelToken) => {
        axios.get(config.apiUrl + `/languages`, config.axiosConfig(token, {cancelToken: cancelToken}))
            .then(res => {
                if (res.data) {
                    callbackSuccess(res);
                }
            })
            .catch(callbackError);
    },
    getAssessments: (callbackSuccess, callbackError, token, cancelToken) => {
        axios.get(config.apiUrl + `/procedures?isNewestVersion=1`, config.axiosConfig(token, {cancelToken: cancelToken}))
            .then(res => {
                if (res.data) {
                    callbackSuccess(res);
                }
            })
            .catch(callbackError);
    },
    getApi: (resource, callbackSuccess, callbackError, token, cancelToken) => {
        axios.get(config.apiUrl + `/${resource}`, config.axiosConfig(token, {cancelToken: cancelToken}))
            .then(res => {
                if (res.data) {
                    callbackSuccess(res);
                }
            })
            .catch(callbackError);
    },
    getIri: (iri, callbackSuccess, callbackError, token, cancelToken) => {
        axios.get(config.apiHost + iri, config.axiosConfig(token, {cancelToken: cancelToken}))
            .then(res => {
                if (res.data) {
                    callbackSuccess(res);
                }
            })
            .catch(callbackError);
    },
}

export default Api;