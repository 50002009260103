import React from "react";
import GlobalTrans from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import PropTypes from 'prop-types';
import {Paper} from "@material-ui/core";
import {getTranslationFromTranslations} from "../../helper/Helper";
import {useStore} from "../../store/useStore";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";

function OverviewWithName(props) {
    const {state, dispatch} = useStore();

    const columns = [
        {
            field: 'name', headerName: GlobalTrans('name'), width: 400, editable: true,
            valueGetter: (params) => (props.hasTranslation)
                ? getTranslationFromTranslations(params.row.translations, state.localeIRI, 'name', params.value)
                : params.value
        },
    ];

    const onEdit = (onEditProps) => {
        dispatch({type: "setEditForm", payload: onEditProps.row});
        dispatch({type: "setPage", payload: props.editPage});
    };

    const columnActions = (colActProps) => {
        return (
            <IconButton onClick={() => onEdit(colActProps)} aria-label={GlobalTrans('title_edit')} title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={props.apiUrl}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'name',
                        label: GlobalTrans('name')
                    }
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
}

OverviewWithName.propTypes = {
    apiUrl: PropTypes.string.isRequired,
    editPage: PropTypes.string.isRequired,
    hasTranslation: PropTypes.bool,
};

OverviewWithName.defaultProps = {
    hasTranslation: false
}

export default OverviewWithName;