import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/app.scss';

import { StoreProvider } from "./store/useStore";
import App from './App';
import reportWebVitals from './reportWebVitals';

function Main() {
    return (
        <StoreProvider>
            <App />
        </StoreProvider>
    );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<Main />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
