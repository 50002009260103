import UseFormatMessage from "./UseFormatMessage";
import {useIntl} from "react-intl";
import messages from "../config/messages";

function GlobalTrans(messageId, values = null) {
    const intl = useIntl();
    let message = messages[messageId];

    if (values) {
        message.values = values;
    }

    if (messageId in messages) {
        return UseFormatMessage(intl, message);
    } else {
        return `${GlobalTrans('Missing translation for')}: ${messageId}`;
    }
}

export function GlobalTransIntl(messageId, intl, values = null) {
    let message = messages[messageId];

    if (values) {
        message.values = values;
    }

    if (messageId in messages) {
        return UseFormatMessage(intl, message);
    } else {
        return `${GlobalTransIntl('Missing translation for', intl)}: ${messageId}`;
    }
}

export default GlobalTrans;