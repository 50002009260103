import React, {useCallback, useEffect, useRef, useState} from 'react';
import PropTypes from "prop-types";
import ImageUpload from "../imageUpload/ImageUpload";
import {Box, Button, Grid, IconButton, Link} from "@material-ui/core";
import config from "../../config/config";
import {GlobalTransIntl} from "../../helper/GlobalTrans";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {useIntl} from "react-intl";
import axios from "axios";
import {useStore} from "../../store/useStore";

const Ocs = (props) => {
    const {state} = useStore();
    const intl = useIntl();
    const referenceRef = useRef();
    const [referenceSheetUpload, setReferenceSheetUpload] = useState(false);
    const [cancelToken] = useState(axios.CancelToken.source());

    const addReferenceSheet = useCallback((referenceSheet) => {
        const newReferences = [...props.references];
        newReferences.push({
            id: referenceSheet['@id'],
            contentUrl: referenceSheet.contentUrl
        });
        props.setReferences(newReferences);
    }, [props]);

    const uploadReferenceSheet = () => {
        referenceRef.current.sendLogo();
    }

    useEffect(() => {
        return () => {
            cancelToken.cancel();
        }
    }, [cancelToken])

    useEffect(() => {
        if (referenceSheetUpload) {
            setReferenceSheetUpload(false);
            referenceRef.current.reset();
        }
    }, [referenceRef, referenceSheetUpload])

    const removeReference = (index) => {
        axios.delete(config.apiHost + props.references[index].id, config.axiosConfig(state.token, {
            cancelToken: cancelToken.token,
            headers: {'Content-Type': 'multipart/form-data'}
        }))
            .then(() => {
                const newReferences = [...props.references];
                newReferences.splice(index, 1);
                props.setReferences(newReferences);
            })
    }

    return (
        <div>
            <Box mb={2}>
                <Grid container spacing={2}>
                    <Grid item>
                        <ImageUpload
                            ref={referenceRef}
                            setImageIRI={addReferenceSheet}
                            showError={() => console.log("error")}
                            catchErrors={() => console.log("error")}
                            setUploadStatus={setReferenceSheetUpload}
                            filetypes={['application/pdf']}
                            returnType={'object'}
                        />
                    </Grid>
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={uploadReferenceSheet}>Send</Button>
                    </Grid>
                </Grid>
            </Box>
            <span>{GlobalTransIntl('references', intl)}</span>
            {
                props.references.map((reference, index) => (
                    <div key={index}>
                        <IconButton onClick={() => removeReference(index)} title={GlobalTransIntl('delete', intl)}>
                            <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                        </IconButton>
                        <Link target={'_blank'}
                              href={config.apiHost + reference.contentUrl}>{reference.contentUrl.replace("/images/", "")}</Link>
                    </div>
                ))
            }
        </div>
    );
};

Ocs.propTypes = {
    references: PropTypes.array.isRequired,
    setReferences: PropTypes.func.isRequired,
}

export default Ocs;