import React from "react";
import Row from "react-bootstrap/cjs/Row";
import Col from "react-bootstrap/cjs/Col";
import GlobalTrans from "../../helper/GlobalTrans";
import {CircularProgress} from "@material-ui/core";
import PropTypes from "prop-types";

function Spinner(props) {
    if (!props.show) {
        return null;
    }

    return (
        <React.Fragment>
            <Row className={"justify-content-md-center" + ((props.rowClass) ? ' ' + props.rowClass : '')}>
                <Col xs={'auto'} className={'align-self--center'}>
                    {
                        props.loadingMessage || GlobalTrans('loading_datagrid')
                    }
                </Col>
                <Col xs={'auto'}>
                    <CircularProgress size={props.size} />
                </Col>
            </Row>
        </React.Fragment>
    );
}

Spinner.prototype = {
    show: PropTypes.bool.isRequired,
    loadingMessage: PropTypes.string,
    rowClass: PropTypes.string
}

Spinner.defaultProps = {
    size: 40
};

export default Spinner;