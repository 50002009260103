import React from "react";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Collapse from "@material-ui/core/Collapse";
import List from "@material-ui/core/List";
import SidebarItemComponent from "./SidebarItemComponent";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronDown, faChevronUp} from '@fortawesome/free-solid-svg-icons';
import Token from "../../helper/Token";
import {useStore} from "../../store/useStore";

function SidebarItem(props) {
    const { state } = useStore();
    const { name, page, Icon, items = [] } = props;
    const isExpandable = items && items.length > 0;
    const [open, setOpen] = React.useState(false);

    function handleClick() {
        setOpen(!open)
    }

    const MenuItemRoot = (
        <SidebarItemComponent className='sidebar--item' page={page} onClick={handleClick}>
            {/* Display an icon if any */}
            {!!Icon && (
                <ListItemIcon className='sidebar--item-icon'>
                    <FontAwesomeIcon icon={Icon} size={'lg'} />
                </ListItemIcon>
            )}
            <ListItemText primary={name} inset={!Icon} className='sidebar--item-text' />
            {/* Display the expand menu if the item has children */}
            {isExpandable && !open && <FontAwesomeIcon icon={faChevronDown} />}
            {isExpandable && open && <FontAwesomeIcon icon={faChevronUp} />}
        </SidebarItemComponent>
    )

    const MenuItemChildren = isExpandable ? (
        <Collapse in={open} timeout="auto" unmountOnExit>
            <Divider />
            <List component="div" disablePadding>
                {
                    items.map((item, index) => {
                        if (!item.roles || Token.hasRoles(state.tokenData, item.roles)) {
                            return <SidebarItem {...item} key={index}/>;
                        }

                        return null;
                    })
                }
            </List>
            <Divider />
        </Collapse>
    ) : null

    return (
        <>
            {MenuItemRoot}
            {MenuItemChildren}
        </>
    );
}

export default SidebarItem;