import React from 'react';
import GlobalTrans from "../../helper/GlobalTrans";
import {Paper} from "@material-ui/core";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import pages from "../../config/pages";
import IconButton from "@material-ui/core/IconButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import {useStore} from "../../store/useStore";
import {getTranslationFromTranslations} from "../../helper/Helper";

const AssessmentsOverview = () => {
    const { state, dispatch } = useStore();

    const columns = [
        {
            field: 'name', headerName: GlobalTrans('name'), width: 400, editable: true,
            valueGetter: (params) => getTranslationFromTranslations(params.row.translations, state.localeIRI, 'name', params.value)
        },
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.ASSESSMENT_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={()=> onEdit(props)} aria-label={GlobalTrans('title_edit')} title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'procedures'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'name',
                        label: GlobalTrans('name')
                    }
                ]}
                dataGridFilter={
                    [
                        {name: 'isNewestVersion', value: 1}
                    ]
                }
                columnActions={columnActions}
            />
        </Paper>
    );
};

export default AssessmentsOverview;