import React from "react";

import Col from "react-bootstrap/cjs/Col";
import Container from "react-bootstrap/cjs/Container";
import Row from "react-bootstrap/cjs/Row";

function Footer() {
    return (
        <footer className='footer'>
            <Container>
                <Row>
                    <Col>
                        Brooklynmaxx GmbH, Agrippinawerft 10, D - 50678 Köln, <a className="footerText" href="https://brooklynmaxx.com" target="_blank" rel="noreferrer">www.brooklynmaxx.com</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;