import React from "react";
import {FormattedMessage} from "react-intl.macro";
import CreateWithName from "../createWithName/CreateWithName";

function CountriesCreate () {
    return (
        <CreateWithName
            apiUrl={'countries'}
            headline={<FormattedMessage id='countries.create.headline' defaultMessage='Create country' />}
            class={'countries'}
            hasTranslation={true}
        />
    );
}

export default CountriesCreate;