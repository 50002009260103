import React from 'react';
import {Box} from "@material-ui/core";
import {useDrag} from "react-dnd";
import config from "../../../../config/config";
import PropTypes from "prop-types";

const GridElementContent = (props) => {
    const {element: originalElement} = props.findContent(props.element.id, props.element.grid, props.element.gridCol);
    const originalGridCol = originalElement.gridCol;

    const [, drag] = useDrag(
        () => ({
            type: config.moduleDragItem,
            item: {...props.element, originalGridCol },
            end: (item, monitor) => {
                const didDrop = monitor.didDrop();

                if (!didDrop) {
                    props.moveElementToGrid(item.originalGridCol, item);
                }
            }
        }), [props]);

    return (
        <Box ref={drag} position={'relative'}>
            <Box p={1} mb={1} className={'theme-border'}>
                {props.children}
            </Box>
        </Box>
    );
};

GridElementContent.propTypes = {
    element: PropTypes.object.isRequired,
    moveElementToGrid: PropTypes.func.isRequired,
    findContent: PropTypes.func.isRequired,
}

export default GridElementContent;