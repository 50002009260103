export const roles = [
    {
        label: 'role_superadmin',
        name: 'ROLE_SUPERADMIN',
    },
    {
        label: 'role_nationaladmin',
        name: 'ROLE_NATIONALADMIN',
    },
    {
        label: 'role_projectadmin',
        name: 'ROLE_PROJECTADMIN',
    },
    {
        label: 'role_user',
        name: 'ROLE_USER',
        create: false,
    },
];

export default roles;