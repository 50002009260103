import React from 'react';
import PropTypes from 'prop-types';
import {Box} from "@material-ui/core";
import {useDrop} from "react-dnd";
import config from "../../config/config";
import ModuleTimePicker from "./ModuleTimePicker";

const TabPanel = (props) => {
    const {children, value, index, addContent, responseTime, updateTabResponseTime, checkboxTimer, ...other} = props;

    const [, drop] = useDrop(
        () => ({
            accept: config.moduleDragItem,
            hover: (item) => {
                if (!item.id) {
                    // add element
                    item.id = addContent(item);
                }
            }
        }),
        [addContent]
    )

    return (
        <Box pt={1} display={(value !== index) ? 'none' : ''}>
            <Box display={(checkboxTimer) ? 'none' : ''} mb={3} maxWidth={300}>
                <ModuleTimePicker setValue={updateTabResponseTime} value={responseTime}/>
            </Box>
            <div ref={drop}{...other}>
                {value === index && (
                    <Box minHeight={200}>
                        {children}
                    </Box>
                )}
            </div>
        </Box>
    );
};

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
    addContent: PropTypes.func.isRequired,
    checkboxTimer: PropTypes.bool.isRequired,
    responseTime: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default TabPanel;