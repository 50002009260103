function ApiFilterBuilder(filter, url = '') {
    filter.forEach((element, index) => {
        if (index === 0) {
            url += '?';
        } else {
            url += '&';
        }

        if (Array.isArray(element.value)) {
            element.value.forEach((elementValue, index) => {
                if (index !== 0) {
                    url += '&';
                }

                url += element.name + '[]=' + elementValue;
            });
        } else {
            if (element.hasOwnProperty('index')) {
                url += element.name + '[' + element.index + ']=' + element.value;
            } else {
                url += element.name + '=' + element.value;
            }
        }
    });

    return url;
}

export default ApiFilterBuilder;