import React from 'react';
import {useDrag} from "react-dnd";
import config from "../../config/config";
import {Box} from "@material-ui/core";

const DraggableElement = (props) => {
    const [, drag] = useDrag(() => ({
        type: config.moduleDragItem,
        item: {...props.element.config, elementType: props.element.type},
        end: (item, monitor) => {
            const didDrop = monitor.didDrop();

            if (!didDrop && item.id) {
                if (item.grid) {
                    props.removeContentFromGrid(item.id, item.grid, item.gridCol);
                } else {
                    props.removeContent(item.id);
                }
            }

            item.id = '';
        }
    }), [props])

    return (
        <div ref={drag}>
            <Box p={2} border={'1px solid black'} className={'text-align-center'}>
                {props.children}
            </Box>
        </div>
    );
};

export default DraggableElement;