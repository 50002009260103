import React, {useCallback, useState} from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText, Grid,
    IconButton,
    Modal
} from "@material-ui/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import GlobalTrans, {GlobalTransIntl} from "./GlobalTrans";
import {useIntl} from "react-intl";
import PropTypes from "prop-types";
import TranslationLanguages from "../components/translationLanguages/TranslationLanguages";

const ModuleElementActions = (props) => {
    const intl = useIntl();

    const [modalOpen, setModalOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openRemoveDialog = () => {
        setDialogOpen(true);
    };

    const closeRemoveDialog = useCallback(() => {
        setDialogOpen(false);
    }, []);

    const removeElement = useCallback(() => {
        closeRemoveDialog();

        props.removeAnswerFunction(props.element.id);

        if (props.element.grid && props.element.gridCol !== undefined) {
            props.removeElementFromGridFunction(props.element.id, props.element.grid, props.element.gridCol);
        } else {
            props.removeElementFunction(props.element.id);
        }
    }, [closeRemoveDialog, props]);

    const getDimensionNameById = useCallback((id) => {
        const dimension = props.element.dimensions.find(dimension => dimension.id === id);

        if (dimension) {
            return dimension.name;
        }

        return '';
    }, [props.element]);

    const getTitle = useCallback(() => {
        return props.elementName + ((!props.element.checkboxDimension && props.element.dimension) ? ' (' + getDimensionNameById(props.element.dimension) + ')' : '');
    }, [getDimensionNameById, props.element.checkboxDimension, props.element.dimension, props.elementName]);

    const onSubmit = useCallback((event) => {
        event.preventDefault();

        props.saveFunction();

        closeModal();
    }, [props]);

    const removeDialog = (
        <Dialog
            open={dialogOpen}
            onClose={closeRemoveDialog}
            aria-describedby= {GlobalTransIntl('dialog_remove_placeholder', intl, {placeholder: getTitle()})}
        >
            <Box p={1}>
                <DialogContent>
                    <DialogContentText>
                        {GlobalTransIntl('dialog_remove_placeholder', intl, {placeholder: getTitle()})}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeRemoveDialog} variant={"contained"}>
                        {GlobalTransIntl('no', intl)}
                    </Button>
                    <Button onClick={removeElement} color="primary" variant={"contained"} autoFocus>
                        {GlobalTransIntl('yes', intl)}
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

    return (
        <React.Fragment>
            <Box position={'absolute'} zIndex={1} right={0}>
                <IconButton onClick={openModal}>
                    <FontAwesomeIcon icon={faEdit} size={"xs"}/>
                </IconButton>
                <IconButton onClick={openRemoveDialog}>
                    <FontAwesomeIcon icon={faTrashAlt} size={"xs"}/>
                </IconButton>
            </Box>

            <Box pr={10}>
                <h2>{getTitle()}</h2>
            </Box>

            <Modal
                open={modalOpen}
                onClose={closeModal}
                className={'MuiModal-root'}
            >
                <div className={'main-modal modal-size-lg'}>
                    <Box mb={2}>
                        <Grid container spacing={2}>
                            <Grid item xs>
                                <h2>{GlobalTransIntl('edit', intl)} {props.elementName}</h2>
                            </Grid>
                            {
                                props.translation &&
                                <Grid item>
                                    <TranslationLanguages
                                        selectedLanguage={props.translationLanguage}
                                        setLanguage={props.setTranslationLanguage}
                                    />
                                </Grid>
                            }
                        </Grid>
                    </Box>
                    <form onSubmit={onSubmit}>
                        {props.children}

                        <Box className={'clearfix'}>
                            <Button variant="contained" color="primary" className={'fa-pull-right'} type={"submit"}>
                                {
                                    GlobalTrans('button_save')
                                }
                            </Button>
                        </Box>
                    </form>
                </div>
            </Modal>
            {removeDialog}
        </React.Fragment>
    );
};

ModuleElementActions.propTypes = {
    saveFunction: PropTypes.func.isRequired,
    removeElementFunction: PropTypes.func.isRequired,
    removeElementFromGridFunction: PropTypes.func.isRequired,
    removeAnswerFunction: PropTypes.func.isRequired,
    element: PropTypes.object.isRequired,
    elementName: PropTypes.string.isRequired,
    translationLanguage: PropTypes.string,
    setTranslationLanguage: PropTypes.func,
    translation: PropTypes.bool,
}

ModuleElementActions.defaultProps = {
    translation: true,
}

export default ModuleElementActions;