import React, {useCallback, useState} from 'react';
import PropTypes from "prop-types";
import {Box, Checkbox, FormControlLabel, Grid} from "@material-ui/core";
import ModuleElementActions from "../../../helper/ModuleElementActions";
import GlobalTrans, {GlobalTransIntl} from "../../../helper/GlobalTrans";
import TextField from "@material-ui/core/TextField";
import {inputOnlyInteger} from "../../../helper/Helper";
import {useIntl} from "react-intl";
import InputAdornment from "@material-ui/core/InputAdornment";

const VideoRecordElement = (props) => {
    const intl = useIntl();

    const elementName = GlobalTrans(props.title);
    const [cameratagAppId, setCameratagAppId] = useState(props.cameratagAppId || '');
    const [minLength, setMinLength] = useState(props.minLength || 3);
    const [maxLength, setMaxLength] = useState(props.maxLength || 30);
    const [videoBitrate, setVideoBitrate] = useState(props.videoBitrate || 1200000);
    const [audioBitrate, setAudioBitrate] = useState(props.audioBitrate || 128000);
    const [width, setWidth] = useState(props.width || 640);
    const [height, setHeight] = useState(props.height || 360);
    const [preroll, setPreroll] = useState(props.preroll || 3);
    const [autoPublish, setAutoPublish] = useState(props.autoPublish ?? true);
    const [autoFinish, setAutoFinish] = useState(props.autoFinish ?? true);

    const saveModal = useCallback(() => {
        // create data
        const data = {
            cameratagAppId,
            minLength,
            maxLength,
            videoBitrate,
            audioBitrate,
            width,
            height,
            autoPublish,
            autoFinish
        };

        // update the data
        props.updateContent(props, data);
    }, [props, cameratagAppId, minLength, maxLength, videoBitrate, audioBitrate, width, height, autoPublish, autoFinish]);

    const modalBody = (
        <React.Fragment>
            <Box mb={2}>
                <FormControlLabel
                    control={<Checkbox checked={autoPublish}
                                       onChange={(e) => setAutoPublish(e.target.checked)}
                                       name={GlobalTransIntl('auto_publish', intl)}/>}
                    label={GlobalTransIntl('auto_publish', intl)}
                />
            </Box>
            <Box mb={2}>
                <FormControlLabel
                    control={<Checkbox checked={autoFinish}
                                       onChange={(e) => setAutoFinish(e.target.checked)}
                                       name={GlobalTransIntl('auto_finish', intl)}/>}
                    label={GlobalTransIntl('auto_finish', intl)}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('cameratag_id')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               setCameratagAppId(e.target.value)
                           }}
                           value={cameratagAppId}
                           required
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('min_length_seconds')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setMinLength)
                           }}
                           value={minLength}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('max_length_seconds')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setMaxLength)
                           }}
                           value={maxLength}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('video_bitrate')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setVideoBitrate)
                           }}
                           value={videoBitrate}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">{GlobalTrans('bps')}</InputAdornment>,
                           }}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('audio_bitrate')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setAudioBitrate)
                           }}
                           value={audioBitrate}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">{GlobalTrans('bps')}</InputAdornment>,
                           }}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('width')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setWidth)
                           }}
                           value={width}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">px</InputAdornment>,
                           }}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('height')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setHeight)
                           }}
                           value={height}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">px</InputAdornment>,
                           }}
                />
            </Box>
            <Box mb={2}>
                <TextField label={GlobalTrans('preroll_time')}
                           autoComplete={'off'}
                           variant="outlined"
                           onChange={(e) => {
                               inputOnlyInteger(e, setPreroll)
                           }}
                           value={preroll}
                           InputProps={{
                               endAdornment: <InputAdornment position="end">{GlobalTrans('seconds')}</InputAdornment>,
                           }}
                />
            </Box>
        </React.Fragment>
    );

    return (
        <Box position={'relative'}>
            <ModuleElementActions
                saveFunction={saveModal}
                removeElementFunction={props.removeContent}
                removeElementFromGridFunction={props.removeContentFromGrid}
                element={props}
                elementName={elementName}
                removeAnswerFunction={props.removeAnswer}
                translation={false}
            >
                {modalBody}
            </ModuleElementActions>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('cameratag_id')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.cameratagAppId}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('min_length_seconds')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.minLength}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('max_length_seconds')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.maxLength}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('video_bitrate')} {GlobalTrans('bps')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.videoBitrate}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('audio_bitrate')} {GlobalTrans('bps')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.audioBitrate}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('width')} px
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.width}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('height')} px
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.height}
                </Grid>
                <Grid item xs={12} md={4}>
                    {GlobalTrans('preroll_time')} {GlobalTrans('seconds')}
                </Grid>
                <Grid item xs={12} md={8}>
                    {props.preroll}
                </Grid>
            </Grid>
        </Box>
    );
};

VideoRecordElement.propTypes = {
    updateContent: PropTypes.func.isRequired,
}

// Default config for the index.js array
export const VideoRecordElementConfig = {
    title: 'video_record',
    cameratagAppId: '',
    minLength: '',
    maxLength: '',
    autoPublish: true,
    autoFinish: true,
    videoBitrate: '',
    audioBitrate: '',
    width: '',
    height: '',
    preroll: '',
    validationType: 'record',
}

export default VideoRecordElement;