import React from "react";
import Paper from "@material-ui/core/Paper";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPen} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import GlobalTrans from "../../helper/GlobalTrans";
import LoadingDataGrid from "../loadingDataGrid/LoadingDataGrid";
import {useStore} from "../../store/useStore";
import pages from "../../config/pages";

function ProjectOverview () {
    const { dispatch } = useStore();

    const columns = [
        {field: 'name', headerName: GlobalTrans('field_name'), width: 350},
        {field: 'provided_by', headerName: GlobalTrans('field_provided_by'), width: 350},
        {
            field: 'status', headerName: GlobalTrans('field_status'), width: 120,
            valueGetter: (params) => params.value.name,
        },
        {
            field: 'country', headerName: GlobalTrans('field_country'), width: 180,
            valueGetter: (params) => params.value.name,
        },
    ];

    const onEdit = (props) => {
        dispatch({type: "setEditForm", payload: props.row});
        dispatch({type: "setPage", payload: pages.PROJECT_CREATE});
    };

    const columnActions = (props) => {
        return (
            <IconButton onClick={()=> onEdit(props)} aria-label={GlobalTrans('title_edit')} title={GlobalTrans('title_edit')}>
                <FontAwesomeIcon icon={faPen} size={"xs"}/>
            </IconButton>
        )
    };

    return (
        <Paper>
            <LoadingDataGrid
                apiUrl={'projects'}
                columns={columns}
                toolbarItems={[
                    {
                        type: 'text',
                        name: 'name',
                        label: GlobalTrans('name')
                    },
                    {
                        type: 'select',
                        getUrl: 'project_statuses',
                        name: 'status',
                        label: GlobalTrans('status'),
                        filterOperator: 'equals'
                    },
                    {
                        type: 'select',
                        getUrl: 'countries',
                        name: 'country',
                        label: GlobalTrans('country'),
                        filterOperator: 'equals'
                    }
                ]}
                columnActions={columnActions}
            />
        </Paper>
    );
}

export default ProjectOverview;