import React from 'react';
import PropTypes from "prop-types";
import {useDrag, useDrop} from "react-dnd";

const DnDSortItem = (props) => {
    const originalIndex = props.findItem(props.itemId).index;

    const [, drag] = useDrag(() => ({
        type: props.itemDragType,
        item: {id: props.itemId, originalIndex},
        end: (item, monitor) => {
            const {id: droppedId, originalIndex} = item;
            const didDrop = monitor.didDrop();

            if (!didDrop) {
                props.moveItem(droppedId, originalIndex);
            }
        },
    }), [props.itemDragType, props.itemId, originalIndex, props.moveItem]);

    const [, drop] = useDrop(() => ({
        accept: props.itemDragType,
        hover: ({id: draggedId}) => {
            if (draggedId !== props.itemId) {
                const {index: overIndex} = props.findItem(props.itemId);

                props.moveItem(draggedId, overIndex);
            }
        },
    }), [props.itemDragType, props.itemId, props.findItem, props.moveItem]);

    return (
        <div ref={(node) => drag(drop(node))} onClick={props.onClick ? (e) => props.onClick(e) : null} className={"cursor-move"}>
            {props.children}
        </div>
    );
};

DnDSortItem.propTypes = {
    itemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    findItem: PropTypes.func.isRequired,
    moveItem: PropTypes.func.isRequired,
    itemDragType: PropTypes.string.isRequired,
    onClick: PropTypes.func,
}

export default DnDSortItem;